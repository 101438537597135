import { axiosInstance } from '../../helpers/api_helper';

const checkVendorExists = async (email: string) => {
  try {
    console.log('Checking if vendor exists for email:', email);

    const response = await axiosInstance.post('/Vendor/CheckVendorExists', { email });

    console.log('API Response from checkVendorExists:', response);

    if (response.data.status === '69') {
      // Vendor already exists
      return {
        exists: true,
        message: response.data.message
      };
    } else {
      // Vendor does not exist
      return {
        exists: false
      };
    }
  } catch (error: any) {
    // Log the actual error details
    console.error('Error in checkVendorExists:', error);

    // Rethrow the error to maintain the error propagation
    throw error;
  }
};

export { checkVendorExists };

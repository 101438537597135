import React, { useMemo, useState, useEffect } from 'react';
import TableContainer from "Common/TableContainer";
import { RecentOrders, fetchRecentOrdersList } from "../../../Common/data/dashboard";
import { Link } from 'react-router-dom';
import { Dropdown } from 'react-bootstrap';

interface ListViewTableProps {
    searchInput: string;
}

const ListViewTable: React.FC<ListViewTableProps> = ({ searchInput }) => {

    const [orders, setOrders] = useState<RecentOrders[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const orderList = await fetchRecentOrdersList();
                setOrders(orderList);
                setLoading(false);
            } catch (error:any) {
                console.error('Error fetching recent orders:', error.message);
                // Handle error as needed
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    const filteredOrders = useMemo(() => {
        // Filter orders based on searchInput
        return orders.filter(order =>
            order.orderID.toLowerCase().includes(searchInput.toLowerCase()) ||
            order.fullName.toLowerCase().includes(searchInput.toLowerCase()) ||
            order.productName.toLowerCase().includes(searchInput.toLowerCase())
        );
    }, [orders, searchInput]);

    const formatDate = (dateString: string) => {
        const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
        const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
        return formattedDate;
    };

    const columns = useMemo(
        () => [
            {
                Header: (<div className="form-check">
                    <input className="form-check-input" type="checkbox" id="checkAll" value="option" />
                </div>),
                Cell: (cellProps: any) => {
                    return (<div className="form-check">
                        <input className="form-check-input" type="checkbox" name="chk_child" defaultValue="option1" />
                    </div>);
                },
                id: '#',
            },
            {
                Header: "Order ID",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps: any) => {
                    return <Link to="#" className="fw-medium link-primary">{cellProps.orderID}</Link>;
                },
            },
            {
                Header: "Customer",
                accessor: "fullName",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Part Number",
                accessor: "productPartNumber",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Part Name",
                accessor: "productName",
                disableFilters: true,
                filterable: true,
                Cell: (cellProps: any) => {
                    return (
                        <Link
                            to={`/orders-overview-custom/${cellProps.row.original.productID}`}
                            className="d-block text-reset"
                        >
                            {cellProps.value}
                        </Link>
                    );
                },
            },
            {
                Header: "Condition",
                accessor: "amount",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Order Date",
                accessor: "orderDate",
                disableFilters: true,
                filterable: true,
                Cell: (cellProps: any) => {
                    return <span>{formatDate(cellProps.value)}</span>;
                },
            },
            {
                Header: "Rating",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps: any) => {
                    return (<React.Fragment>
                    {cellProps.rating} <i className="bi bi-star-half ms-1 text-warning fs-12"></i>
                    </React.Fragment>)
                }
            },
            {
                Header: "Action",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps: any) => {
                    return (
                        <React.Fragment>
                            <Dropdown className="text-center">
                                <Dropdown.Toggle href="#!" className="btn btn-soft-secondary btn-sm btn-icon dropdown arrow-none">
                                    <i className="mdi mdi-dots-horizontal" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu as="ul" className="dropdown-menu-end">
                                    <li>
                                        <Dropdown.Item href="#" className="remove-list">
                                            <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />Delete
                                        </Dropdown.Item>
                                    </li>
                                </Dropdown.Menu>
                            </Dropdown>
                        </React.Fragment>
                    )
                },
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <TableContainer
                columns={(columns || [])}
                data={(filteredOrders || [])}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                className="custom-header-css"
                tableClass="table-centered align-middle table-nowrap mb-0"
                theadClass="text-muted table-light"
                SearchPlaceholder='Search Products...'
            />
        </React.Fragment>
    );
}

export default ListViewTable;
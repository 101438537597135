import React, { useState } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';

interface MarkAsBoughtModalProps {
  show: boolean;
  handleClose: () => void;
  handleMarkAsBoughtSubmit: (customerId: string) => void;
}

const MarkAsBoughtModal: React.FC<MarkAsBoughtModalProps> = ({ show, handleClose, handleMarkAsBoughtSubmit }) => {
  const [customerId, setCustomerId] = useState<string>('');
  const [showNote, setShowNote] = useState<boolean>(true);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCustomerId(e.target.value);
  };

  const handleSubmit = () => {
    handleMarkAsBoughtSubmit(customerId);
  };

  return (
    <Modal show={show} onHide={() => { handleClose(); setShowNote(true); }}>
      <Modal.Header closeButton>
        <Modal.Title>Enter Customer ID</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {showNote && (
          <Alert variant="info" onClose={() => setShowNote(false)} dismissible>
            Ask the customer who purchased the product to send the Customer ID, which can be found in their profile using the chat.
          </Alert>
        )}
        <Form.Group controlId="customerId">
          <Form.Label>Customer ID:</Form.Label>
          <Form.Control
            type="text"
            value={customerId}
            onChange={handleInputChange}
            placeholder="Enter Customer ID"
          />
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="danger" onClick={handleClose}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSubmit}>
          Mark as Bought
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MarkAsBoughtModal;

import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchProductList, ProductList } from 'Common/data/products';
import TableContainer from 'Common/TableContainer';
import { Col, Dropdown, Form, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { insertOrder } from 'Common/data/insertorder';
import MarkAsBoughtModal from '../Modal/index';
import { updateProductStatus } from 'Common/data/updateproductstatus';

interface ProductTableProps {
  selectedCategories: string[];
}

const ProductTable: React.FC<ProductTableProps> = ({ selectedCategories }) => {
  const navigate = useNavigate();
  const [searchQuery, setSearchQuery] = useState('');
  const [productList, setProductList] = useState<ProductList[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedProductId, setSelectedProductId] = useState('');
  // const [pendingDeleteProductId, setPendingDeleteProductId] = useState('');

  useEffect(() => {
    const fetchProductListData = async () => {
      try {
        const productDataPromises = await fetchProductList();
        const productData = await Promise.all(productDataPromises);

        // Apply category filtering if selectedCategories is not empty
        const filteredData =
          selectedCategories.length > 0
            ? productData.filter((product) => {
                // Check if any of the selected category names match the product's category name
                return selectedCategories.some(
                  (selectedCatName) => selectedCatName === product.catName
                );
              })
            : productData;

        setProductList(filteredData);
      } catch (error: any) {
        console.error('Error fetching product list:', (error as Error).message);
      }
    };

    fetchProductListData();
  }, [selectedCategories]);

  const handleMarkAsBoughtClick = async (productID: string) => {
    try {
      setSelectedProductId(productID);
      setShowModal(true);
      // Handle the response or perform additional actions
      console.log('Mark as bought initiated for product ID:', productID);
    } catch (error: any) {
      console.error('Error initiating mark as bought:', error.message);
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleMarkAsBoughtSubmit = async (customerId: string) => {
    try {
      const orderData = await insertOrder({
        productID: selectedProductId,
        customerID: customerId,
        vendorID: JSON.parse(localStorage.getItem('authUser') || '{}').vendorID || '',
        qty: '0',
        price: '0',
        comment: 'Not needed',
      });

      // Handle the response or perform additional actions
      console.log('Order inserted successfully:', orderData);

      // Close the modal after successful submission
      setShowModal(false);

      window.location.reload();
    } catch (error: any) {
      console.error('Error inserting order:', error.message);
    }
  };

  const handleValidDate = (date: any) => {
    const date1 = moment(new Date(date)).format('DD MMM Y');
    return date1;
  };

  const handleValidTime = (time: any) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = '';
    if (getHour >= 12) {
      meridiem = 'PM';
    } else {
      meridiem = 'AM';
    }
    const updateTime =
      moment(getTime, 'hh:mm').format('hh:mm') + ' ' + meridiem;
    return updateTime;
  };

  const filteredProductList = useMemo(() => {
    return productList.filter((product) =>
      product.productName.toLowerCase().includes(searchQuery.toLowerCase())
    );
  }, [productList, searchQuery]);

  const columns = useMemo(
    () => [
      {
        Header: 'Part Number',
        accessor: 'productPartNumber',
        Filter: false,
      },
      {
        Header: 'Part name',
        // Disable sorting for this column
        disableSortBy: true,
        Cell: (product: any) => (
          <>
            <div className="d-flex align-items-center">
              <div className="flex-shrink-0 me-2">
                <div className="avatar-sm bg-light rounded p-1">
                  <img
                    src={product.row.original.productImage}
                    alt=""
                    className="img-fluid d-block avatar-xs"
                    style={{
                      width: '100%',
                      height: '100%',
                      objectFit: 'cover',
                    }}
                  />
                </div>
              </div>
              <div className="flex-grow-1">
                <h5 className="fs-14 mb-1">
                  {/* Wrap the product name inside Link */}
                  <Link
                    to={`/product-overview/${product.row.original.productID}`}
                    className="d-block text-reset"
                  >
                    {product.row.original.productName}
                  </Link>
                </h5>
                <p className="text-muted mb-0">
                  Category :{' '}
                  <span className="fw-medium">
                    {' '}
                    {product.row.original.catName}
                  </span>
                </p>
              </div>
            </div>
          </>
        ),
      },
      {
        Header: 'Condition',
        accessor: 'productStateType',
        Filter: false,
      },
      {
        Header: 'Publish',
        Filter: false,
        accessor: (cellProps: any) => {
          return (
            <span>
              {handleValidDate(cellProps.productCreated)}
              <small className="text-muted ms-1">
                {handleValidTime(cellProps.productCreated)}
              </small>
            </span>
          );
        },
      },
      {
        Header: 'Action',
        Cell: (cellProps: any) => {
          const handleEditClick = () => {
            // Redirect to the CreateProduct page with the product ID as a URL parameter
            window.location.href = `/#/product-create-edit/${cellProps.row.original.productID}`;
          };

          const handleMarkAsBought = () => {
            // Call the handleMarkAsBoughtClick function with the productID
            handleMarkAsBoughtClick(
              cellProps.row.original.productID
            );
          };

          const handleUpdateStatus = async () => {
            try {
              const productStatus = await updateProductStatus({
                productID: cellProps.row.original.productID, // Access the product ID from the line item
                status: 0,
              });
          
              window.location.reload();
              
              console.log('Product status updated successfully:', productStatus);
            } catch (error: any) {
              console.error('Error updating product status:', error.message);
            }
          };

          return (
            <React.Fragment>
              <Dropdown className="text-center">
                <Dropdown.Toggle
                  className="btn btn-ghost-primary btn-icon btn-sm arrow-none"
                >
                  <i className="mdi mdi-dots-horizontal" />
                </Dropdown.Toggle>
                <Dropdown.Menu as="ul" className="dropdown-menu-end">
                  <li>
                    <Dropdown.Item onClick={handleEditClick}>
                      <i className="ri-pencil-fill align-bottom me-2 text-muted" />{' '}
                      Edit
                    </Dropdown.Item>
                  </li>
                  <li>
                    <Dropdown.Item onClick={handleMarkAsBought}>
                      <i className="ri-pencil-fill align-bottom me-2 text-muted" />{' '}
                      Mark as bought
                    </Dropdown.Item>
                  </li>
                  <li>
                    <Dropdown.Item onClick={handleUpdateStatus}>
                      <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />{' '}
                      Delete
                    </Dropdown.Item>
                  </li>
                </Dropdown.Menu>
              </Dropdown>
            </React.Fragment>
          );
        },
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Row className="g-4 mb-4">
        <Col className="col-sm-auto">
          <div>
            <Link
              to="/product-create"
              className="btn custom-primary-btn"
              id="addproduct-btn"
            >
              <i className="ri-add-line align-bottom me-1"></i> Add Product
            </Link>
          </div>
        </Col>
        <Col className="col-sm">
          <div className="d-flex justify-content-sm-end">
            <div className="search-box ms-2">
              <Form.Control
                type="text"
                autoComplete="off"
                id="searchProductList"
                placeholder="Search Parts..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <i className="ri-search-line search-icon"></i>
            </div>
          </div>
        </Col>
      </Row>
      <div>
        <TableContainer
          columns={columns}
          data={(filteredProductList || [])}
          isAddUserList={false}
          customPageSize={10}
          tableClass="gridjs-table"
          theadClass="gridjs-th"
          isProductsFilter={true}
          SearchPlaceholder="Search Products..."
        />
      </div>

      {/* Display the modal for entering customer ID */}
      <MarkAsBoughtModal
        show={showModal}
        handleClose={handleCloseModal}
        handleMarkAsBoughtSubmit={handleMarkAsBoughtSubmit}
      />
    </React.Fragment>
  );
};

export default ProductTable;

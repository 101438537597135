import { axiosInstance } from '../../helpers/api_helper';

const fetchAllConversationsApi = async () => {
  try {
    const response = await axiosInstance.get(`/Chat`);
    return response.data;
  } catch (error) {
    console.error('Error fetching conversations:', error);
    throw error;
  }
};

export { fetchAllConversationsApi };
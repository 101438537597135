import { axiosInstance } from '../../helpers/api_helper';

const getVendorByEmail = async (email: string) => {
  try {
    console.log('Fetching vendor details for email:', email);

    const response = await axiosInstance.get(`/Vendor/VendorDetailsByEmail/${email}`);

    console.log('API Response from getVendorByEmail:', response);
    
    console.log('API Response from getVendorByEmail 2:', response.data.status);

    if (response.data) { // Corrected the condition
      // Handle success, if needed
      return response.data;
    } else {
      // Handle error
      throw new Error('Error fetching vendor details');
    }
  } catch (error:any) {
    // Handle network or other errors
    throw new Error(`Error: ${error.message}`);
  }
};

export { getVendorByEmail };

import { axiosInstance } from '../../helpers/api_helper';

const checkCustomerExists = async (email: string) => {
  try {
    console.log('Checking if customer exists for email:', email);

    const response = await axiosInstance.post('/Customers/CheckEmailExists/', { email });

    console.log('API Response from checkCustomerExists:', response);

    if (response.data.status === '69') {
      // Customer already exists
      return {
        exists: true,
        message: response.data.message
      };
    } else {
      // Customer does not exist
      return {
        exists: false
      };
    }
  } catch (error: any) {
    // Log the actual error details
    console.error('Error in checkCustomerExists:', error);

    // Rethrow the error to maintain the error propagation
    throw error;
  }
};

export { checkCustomerExists };

import React, { useState } from 'react';
import { Button, Card, Col, Form, Row, Alert, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { changePassword } from '../../../Common/data/changepassword';
import { vendorLogin } from 'Common/data/vendorlogin';

// Formik Validation
import * as Yup from 'yup';
import { useFormik } from 'formik';

const ChangePassword = () => {
  const [passwordShow, setPasswordShow] = useState<any>(false);
  const [confirmPasswordShow, setConfirmPasswordShow] = useState<any>(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [oldPasswordError, setOldPasswordError] = useState<string>('');

  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values need to be changed
    enableReinitialize: true,

    initialValues: {
      old_password: '',
      password: '',
      confirm_password: '',
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required('Please Enter Your Old Password'),
      password: Yup.string().required('Please Enter Your Password').matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/, 'Must Contain 8 Characters, One Uppercase, One Lowercase, One Number, and One Special Case Character'),
      confirm_password: Yup.string().required('Please Confirm Password').oneOf([Yup.ref('password')], 'Passwords must match'),
    }),
    onSubmit: async (values, { setErrors }) => {
      try {
        setLoading(true);

        // Retrieve email from local storage
        const authUser = JSON.parse(localStorage.getItem('authUser') || '{}');
        const email = authUser.vendorEmail;

        console.log('Email:', email);

        // Attempt to login with the provided old password
        const loginResponse = await vendorLogin(email, values.old_password);

        // Check if login failed due to incorrect old password
        if (loginResponse.status === '00') {
          // Password is correct, proceed to change password
          const vendorID = JSON.parse(localStorage.getItem('authUser') || '{}').vendorID || '';
          const formData = {
            vendorID,
            password: values.password,
          };

          // Call the API to change the password
          const response = await changePassword(formData);

          // Handle success, e.g., show a success message
          console.log('Password changed successfully:', response);
          setShowSuccessAlert(true);
        } else {
          // Old password is incorrect, set error message
          setOldPasswordError('Incorrect old password');
        }
      } catch (error: any) {
        // Handle error, e.g., show an error message
        console.error('Error changing password:', error.message);
        setErrors({ old_password: 'Old password is wrong' });
      } finally {
        setLoading(false);
      }
    },
  });

  return (
    <React.Fragment>
      <Row>
        <Col lg={4}>
          <h5 className="fs-16">Change Password</h5>
        </Col>
        <Col lg={8}>
          <Card>
            <Card.Body>
              {showSuccessAlert && (
                <Alert variant="success" onClose={() => setShowSuccessAlert(false)} dismissible>
                  Password changed successfully!
                </Alert>
              )}
              <Row className="g-3">
                <Col lg={4}>
                  <Form>
                    <Form.Label htmlFor="old-password-input">Old Password</Form.Label>
                    <div className="position-relative auth-pass-inputgroup">
                      <Form.Control
                        type={passwordShow ? 'text' : 'password'}
                        className="pe-5 password-input"
                        placeholder="Enter old password"
                        id="old-password-input"
                        name="old_password"
                        autoComplete="off"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.old_password || ''}
                        isInvalid={!!oldPasswordError || (validation.touched.old_password && validation.errors.old_password)}
                      />
                      {(validation.touched.old_password && validation.errors.old_password) || oldPasswordError ? (
                        <Form.Control.Feedback type="invalid">
                          <div>{oldPasswordError || validation.errors.old_password}</div>
                        </Form.Control.Feedback>
                      ) : null}
                      {!validation.touched.old_password || !validation.errors.old_password || oldPasswordError ? (
                        <Button
                          variant="link"
                          className="btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                          type="button"
                          onClick={() => setPasswordShow(!passwordShow)}
                        >
                          <i className={passwordShow ? 'ri-eye-fill align-middle' : 'ri-eye-off-fill align-middle'}></i>
                        </Button>
                      ) : null}
                    </div>
                  </Form>
                </Col>
                <Col lg={4}>
                  <Form>
                    <Form.Label htmlFor="password-input">Password</Form.Label>
                    <div className="position-relative auth-pass-inputgroup">
                      <Form.Control
                        type={passwordShow ? 'text' : 'password'}
                        className="pe-5 password-input"
                        placeholder="Enter password"
                        id="password-input"
                        name="password"
                        autoComplete="off"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password || ''}
                        isInvalid={validation.touched.password && validation.errors.password}
                      />
                      {validation.touched.password && validation.errors.password ? (
                        <Form.Control.Feedback type="invalid">
                          <div>{validation.errors.password}</div>
                        </Form.Control.Feedback>
                      ) : null}
                      {!validation.touched.password || !validation.errors.password ? (
                        <Button
                          variant="link"
                          className="btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                          type="button"
                          id="password-addon"
                          onClick={() => setPasswordShow(!passwordShow)}
                        >
                          <i className="ri-eye-fill align-middle"></i>
                        </Button>
                      ) : null}
                    </div>
                  </Form>
                </Col>

                <Col lg={4}>
                  <Form>
                    <Form.Label htmlFor="confirm-password-input">Confirm Password</Form.Label>
                    <div className="position-relative auth-pass-inputgroup">
                      <Form.Control
                        type={confirmPasswordShow ? 'text' : 'password'}
                        className="pe-5 password-input"
                        placeholder="Confirm password"
                        id="confirm-password-input"
                        name="confirm_password"
                        autoComplete="off"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.confirm_password || ''}
                        isInvalid={validation.touched.confirm_password && validation.errors.confirm_password}
                      />
                      {validation.touched.confirm_password && validation.errors.confirm_password ? (
                        <Form.Control.Feedback type="invalid">
                          <div>{validation.errors.confirm_password}</div>
                        </Form.Control.Feedback>
                      ) : null}
                      {!validation.touched.confirm_password || !validation.errors.confirm_password ? (
                        <Button
                          variant="link"
                          className="btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon"
                          type="button"
                          onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}
                        >
                          <i className="ri-eye-fill align-middle"></i>
                        </Button>
                      ) : null}
                    </div>
                  </Form>
                </Col>

                <Col lg={12}>
                  <div className="text-end">
                    <Button
                      variant="success"
                      type="submit"
                      className="custom-primary-btn"
                      onClick={validation.handleSubmit}
                      disabled={loading}
                    >
                      {loading ? <Spinner animation="border" size="sm" /> : 'Change Password'}
                    </Button>
                  </div>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default ChangePassword;

// Import Images
import img1 from 'assets/images/products/img-121.jpg'
import img4 from 'assets/images/products/img-107.jpg'
import img5 from 'assets/images/products/img-108.jpg'
import img6 from 'assets/images/products/img-123.jpeg'
import img7 from 'assets/images/products/img-122.jpg'
import img10 from 'assets/images/products/img-119.jpg'
import img15 from 'assets/images/products/img-120.png'

const profile = [
    {
        id: '#TBT42101',
        img: img10,
        rating: '4.9',
        title: "Aircraft Engine",
        price: '266.24',
        stock: '45',
        type: 'powerplant',
        orders: '48',
    },
    {
        id: '#TBT42102',
        img: img15,
        rating: '4.2',
        title: "Navigation System",
        price: '742.00',
        stock: '06',
        type: ' Avionics',
        orders: '30',
    },
    {
        id: '#TBT42103',
        img: img1,
        rating: '3.8',
        title: "Wing",
        price: '113.24',
        stock: '10',
        type: 'aircraft components',
        orders: '48',
    },
    {
        id: '#TBT42104',
        img: img7,
        rating: '4.7',
        title: "Avionic Panel",
        price: '96.26',
        stock: '15',
        type: 'cockpit instruments',
        orders: '40',
    },
    {
        id: '#TBT42105',
        img: img4,
        rating: '4.7',
        title: "Windshields",
        price: '24.07',
        stock: '08',
        type: 'power plant',
        orders: '55',
    },
    {
        id: '#TBT42106',
        img: img5,
        rating: '4.0',
        title: "Airplane Fuselage",
        price: '229.00',
        stock: '15',
        type: 'avionics',
        orders: '48',
    },
    {
        id: '#TBT42107',
        img: img6,
        rating: '4.8',
        title: "Landing Gear",
        price: '62.40',
        stock: '12',
        type: 'airframe components',
        orders: '45',
    }]

const account = [
    {
        OrderID: '#TBT42101',
        ProductName: 'Metal Pipes',
        Amount: 'OH',
        OrderDate: '18 Dec, 2022',
        PaymentMethod: 'Mastercard',
        DeliveryStatus: 'Delivered',
        rating: '4.9'
    },
    {
        OrderID: '#TBT42102',
        ProductName: "Aviation Hardware",
        Amount: 'NS',
        OrderDate: '02 Jan, 2023',
        PaymentMethod: 'Visa',
        DeliveryStatus: 'Pickups',
        rating: '4.2'
    },
    {
        OrderID: '#TBT42103',
        ProductName: 'Rubber & Polymer',
        Amount: 'SV',
        OrderDate: '24 Nov, 2022',
        PaymentMethod: 'COD',
        DeliveryStatus: 'Inprogress',
        rating: '3.8'
    },
    {
        OrderID: '#TBT42104',
        ProductName: 'Aircraft Engine',
        Amount: 'AR',
        OrderDate: '12 Jan, 2023',
        PaymentMethod: 'Visa',
        DeliveryStatus: 'Pending',
        rating: '4.7'
    },
    {
        OrderID: '#TBT42105',
        ProductName: 'Avionics Part',
        Amount: 'RP',
        OrderDate: '08 Aug, 2022',
        PaymentMethod: 'Mastercard',
        DeliveryStatus: 'Returns',
        rating: '4.7'
    },
    {
        OrderID: '#TBT42106',
        ProductName: 'Landing Gear',
        Amount: 'New',
        OrderDate: '08 Oct, 2022',
        PaymentMethod: 'Visa',
        DeliveryStatus: 'Cancelled',
        rating: '4.8'
    },
    {
        OrderID: '#TBT42107',
        ProductName: 'Airplane Fuselage',
        Amount: 'New',
        OrderDate: '11 Feb, 2021',
        PaymentMethod: 'COD',
        DeliveryStatus: 'Delivered',
        rating: '4.0'
    },
    {
        OrderID: '#TBT42108',
        ProductName: 'Windshield',
        Amount: 'FN',
        OrderDate: '28 Oct, 2022',
        PaymentMethod: 'Mastercard',
        DeliveryStatus: 'Pickups',
        rating: '4.7'
    },
    {
        OrderID: '#TBT42109',
        ProductName: '  Avionics Panel',
        Amount: 'NS',
        OrderDate: '19 June, 2021',
        PaymentMethod: 'Visa',
        DeliveryStatus: 'Pending',
        rating: '4.7'
    },
    {
        OrderID: '#TBT42110',
        ProductName: 'Wing',
        Amount: 'OH',
        OrderDate: '25 Nov, 2021',
        PaymentMethod: 'COD',
        DeliveryStatus: 'Returns',
        rating: '3.8'
    }
]
const accountTransaction = [
    {
        TransactionID: 'c1bf44fd100fff',
        From: ' Cassius Brock',
        To: 'Admin',
        Timestamp: '02 Feb, 2023',
        time: ' 01:47AM  ',
        PaymentMethod: '    PayPal',
        Amount: '$266.24',
        Status: 'Success'
    },
    {
        TransactionID: 'e5r4f1g5h2npli',
        From: 'Admin',
        To: 'HDFC',
        Timestamp: '29 Jan, 2023',
        time: ' 03:20PM',
        PaymentMethod: 'Credit Card',
        Amount: '$462.36',
        Status: 'Pending'
    },
    {
        TransactionID: 'c94b5581418c4   ',
        From: 'Admin',
        To: 'HDFC',
        Timestamp: '29 Jan, 2023',
        time: '04:33PM',
        PaymentMethod: '    Credit Card',
        Amount: '$6.00',
        Status: 'Success'
    },
    {
        TransactionID: 'c1bf44fd100fff',
        From: ' Cassius Brock',
        To: 'Admin',
        Timestamp: '02 Feb, 2023',
        time: ' 01:47AM  ',
        PaymentMethod: '    PayPal',
        Amount: '$266.24',
        Status: 'Success'
    },
    {
        TransactionID: '1deffa9713917e',
        From: 'Scott Wilson ',
        To: 'Admin',
        Timestamp: '17 Jan, 2023 ',
        time: '01:35AM',
        PaymentMethod: 'Debit Card',
        Amount: '$364.78',
        Status: 'Failed'
    },
    {
        TransactionID: 'v98p141d5f4j14',
        From: 'Kristina Hooper  ',
        To: 'Admin',
        Timestamp: '29 Dec, 2022',
        time: '11:49AM',
        PaymentMethod: 'Debit Card',
        Amount: '$723.91',
        Status: 'Success'
    },
    {
        TransactionID: 'v98p141d5f4j14',
        From: 'Admin',
        To: 'Jacques Leon',
        Timestamp: '13 Dec, 2022',
        time: '10:30AM',
        PaymentMethod: 'PayPal',
        Amount: '$340.00',
        Status: 'Success'
    },
    {
        TransactionID: 'v98p141d5f4j14',
        From: 'HDFC',
        To: 'Admin',
        Timestamp: '13 Dec, 2022',
        time: '10:30AM',
        PaymentMethod: 'Online Banking',
        Amount: '$954.30',
        Status: 'Pending'
    },
    {
        TransactionID: 'sdsa78gh48t98',
        From: 'Admin',
        To: 'HDFC',
        Timestamp: '11 Dec, 2022',
        time: ' 09:46PM',
        PaymentMethod: 'Online Banking',
        Amount: '$32.14',
        Status: 'Failed'
    },
    {
        TransactionID: '186aa96d8010',
        From: 'Admin',
        To: 'Daniel Gonzalez',
        Timestamp: '02 Nov, 2022',
        time: '07:30PM',
        PaymentMethod: 'PayPal',
        Amount: '$74.10',
        Status: 'Success'
    },
    {
        TransactionID: '61d994f025ac',
        From: 'Gabrielle Holden ',
        To: 'Admin',
        Timestamp: '29 Oct, 2022',
        time: '12:58AM',
        PaymentMethod: 'Credit Card',
        Amount: '$698.37',
        Status: 'Success'
    },
]

export { profile, account, accountTransaction }
import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../helpers/api_helper';

export interface VendorConfirmationResponse {
    success: boolean;
    message: string;
    // Add any additional fields you expect in the response
}

async function confirmVendor(vendorId: string): Promise<VendorConfirmationResponse> {
    const apiUrl = `/Vendor/ConfirmVendor/${vendorId}`;

    try {
        const response: AxiosResponse<VendorConfirmationResponse> = await axiosInstance.get(apiUrl);

        // Log the entire response data
        console.log('Vendor Confirmation Response:', response.data);

        // Return the response data
        return response.data;
    } catch (error: any) {
        console.error('Error confirming vendor:', error.message);
        // Handle errors here
        throw error;
    }
}

export { confirmVendor };

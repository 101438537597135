import React, { useState, useEffect } from 'react';
import { Card, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { stockReport } from "../../Common/data";
import { ProductList, fetchProductList } from '../../Common/data/products';

const Status = ({ status }: any) => {
    switch (status) {
        case "In Stock":
            return (<span className="badge bg-success-subtle text-success"> {status}</span>)
        case "Low Stock":
            return (<span className="badge bg-warning-subtle text-warning"> {status}</span>)
        default:
            return (<span className="badge bg-danger-subtle text-danger"> {status}</span>)
    }
}

const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    return formattedDate;
};


const StockReport = () => {

    const [productData, setProductData] = useState<ProductList[]>([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const productList = await fetchProductList();
                setProductData(productList);
            } catch (error:any) {
                console.error('Error fetching product data:', error.message);
            }
        };

        fetchData();
    }, []);

    return (
        <React.Fragment>
            <Col xxl={9}>
                <Card>
                    <Card.Header className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Stock Report</h4>

                    </Card.Header>

                    <Card.Body>
                        <div className="table-responsive table-card">
                            <table className="table table-borderless table-centered align-middle table-nowrap mb-0">
                                <thead className="text-muted table-light">
                                    <tr>
                                        <th scope="col">Part ID</th>
                                        <th scope="col">Part Number</th>
                                        <th scope="col">Part Name</th>
                                        <th scope="col">Updated Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {productData.slice(0, 6).map((item, key) => (
                                        <tr key={key}>
                                            <td>
                                                <Link to="/product-overview" className="fw-medium link-primary">{item.productID}</Link>
                                            </td>
                                            <td>{item.productPartNumber}</td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-shrink-0 me-2">
                                                        <img src={item.productImage} alt="" className="avatar-xs rounded-circle" />
                                                    </div>
                                                    <div className="flex-grow-1"><Link to="/product-overview" className="text-reset">{item.productName}</Link></div>
                                                </div>
                                            </td>
                                            <td>{formatDate(item.productCreated)}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </React.Fragment>
    );
};

export default StockReport;

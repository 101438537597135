import { axiosInstance } from '../../helpers/api_helper';
import axios from 'axios';

// addproductdocument.ts
type ApiResponse = {
  ResponseCode?: string;
  documentUrl?: string;
  data?: {
    ResponseCode?: string;
  };
};

  
  const addProductDocument = async (formData: FormData): Promise<ApiResponse> => {
    try {
      console.log('FormData in addProductDocument:', formData);
  
      //const response = await axiosInstance.post('/Products/UploadProductDocumentation', formData, {
      const response = await axios.post('https://aeropartx.hatini.com.ng/Products/UploadProductDocumentation', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
  
      console.log('API Response from addProductDocument:', response);
  
      //if (response.data && response.data.ResponseCode === '00') {
      if (response.data && response.data.data.ResponseCode === '00') {
        return response.data;
      } else {
        console.error('Error adding product document. Server response:', response);
  
        // Log additional details about the response
        console.error('Response data:', response.data);
        console.error('Response status:', response.status);
        
        throw new Error(`Error adding product document. Server responded with status ${response.status}`);
      }
    } catch (error: any) {
      // Handle network or other errors
      console.error('Error in addProductDocument:', error.message || 'Undefined error');
      throw new Error(`Error: ${error.message || 'Undefined error'}`);
    }
  };
  
  export { addProductDocument };
  
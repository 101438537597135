import React, { useEffect, useState } from 'react';
import { Alert, Button, Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { GoogleLogin } from "@react-oauth/google";
import { jwtDecode } from "jwt-decode";
import { checkVendorExists } from 'Common/data/checkvendorexists';
import { vendorLogin } from 'Common/data/vendorlogin';
import { checkCustomerExists } from 'Common/data/checkcustomerexists';

import GoogleLoginUnavailableAlert from './GoogleLoginUnavailableAlert';

// Import Images
import logoDark from 'assets/images/logo-dark.png'
import logoLight from 'assets/images/logo-light.png'
import img1 from 'assets/images/auth/img-1.png'

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { registerUser, apiError, resetRegisterFlag } from "slices/thunk";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';

import { Link, useNavigate } from "react-router-dom";
import { values } from 'lodash';

const Register = () => {

    document.title = "Register | AeroPartX";

    const navigate = useNavigate();
    const dispatch = useDispatch<any>();

    const [passwordShow, setPasswordShow] = useState<any>(false);
    const [timer, setTimer] = useState<number>(0);
    const [loader, setLoader] = useState<boolean>(false);
    const [errorFromServer, setErrorFromServer] = useState<string>('');
    const [alertMessage, setAlertMessage] = useState<string>('');
    const [alertType, setAlertType] = useState<'success' | 'danger'>('success');

    const validation: any = useFormik({
        // enableReinitialize : use this flag when initial values needs to be changed
        enableReinitialize: true,
    
        initialValues: {
            email: '',
            username: '',
            password: '',
            vendorPhone: 'Not updated',
            vendorAddress: 'Not updated',
            country: 'Not updated',
            city: 'Not updated',
            vendorRCNo: 'Not updated',
        },
        validationSchema: Yup.object({
            email: Yup.string().required("Please Enter Your Email"),
            username: Yup.string().required("Please Enter Your Full name"),
            password: Yup.string().required("Please Enter Your Password").matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/, "Must Contain 8 Characters, One Uppercase, One Lowercase and One Number"),
            googleResponse: Yup.object().nullable(),
        }),
        onSubmit: async (values, { setSubmitting }) => {
            setLoader(true); // Start the loader
            
            console.log('Submitting form:', values);
            const { email, username: vendorName, password: vendorPassword, vendorPhone, vendorAddress, country, city, vendorRCNo } = values;
            
            const vendorExistsResponse = await checkVendorExists(email);
            const customerExistsResponse = await checkCustomerExists(email);
        
            if (vendorExistsResponse.exists || customerExistsResponse.exists) {
                setErrorFromServer(vendorExistsResponse.message || customerExistsResponse.message);
                setLoader(false); // Stop the loader if vendor or customer exists
                return;
            }

            console.log('Regular registration');
            // Regular registration
            const vendorData = { vendorEmail: email, vendorName, vendorPassword, vendorPhone, vendorAddress, country, city, vendorRCNo };
                
            try {
                await dispatch(registerUser(vendorData));
            } catch (error) {
                console.error('Error during regular registration:', error);
            }
        
            // Stop the loader after a certain duration (e.g., 3 seconds)
            setTimeout(() => {
                setLoader(false);
            }, 3000);
    
            setSubmitting(false);

        }          
    });    

    const selectProperties = createSelector(
        (state: any) => state.Account,
        (account) => ({
            success: account.success,
            error: account.error
        })
    );

    const { error, success } = useSelector(selectProperties);

    useEffect(() => {
        dispatch(apiError());
    }, [dispatch]);

    useEffect(() => {
        if (success) {
            setTimeout(() => navigate("/login"), 60000);
            setTimer(60)
        }

        setLoader(false)
    }, [dispatch, success, error, navigate]);


    useEffect(() => {
        if (timer) {
            setInterval(() => setTimer(timer - 1), 1000);
        }
    }, [timer])

    useEffect(() => {
        const timerId = setTimeout(() => {
            dispatch(resetRegisterFlag()); // Dispatch action to reset the registration flag
        }, 30000); // 30 seconds
    
        return () => clearTimeout(timerId); // Clear the timer on component unmount or when success changes
    }, [dispatch, success]);

    const handleGoogleSignup = async (responseDecoded: any) => {
        if (!(responseDecoded.email && responseDecoded.email_verified === true)) {
            console.error('Invalid Google signup response:', responseDecoded);
            setAlertType('danger');
            setAlertMessage('Invalid signup response from Google. Please try again later.');
            return;
        }
    
        const vendorEmail = responseDecoded.email;
        try {
            // Check if the vendor exists
            const vendorExistsResponse = await checkVendorExists(vendorEmail);
            // Check if the customer exists
            const customerExistsResponse = await checkCustomerExists(vendorEmail);
      
            if (vendorExistsResponse.exists || customerExistsResponse.exists === false) {
                console.log("Vendor does not exist. Proceeding with registration...");

                // Vendor does not exist, proceed with registration
                const randomPassword = Math.random().toString(36).slice(-8);
        
                await vendorLogin(vendorEmail, randomPassword);
        
                setAlertType('success');
                setAlertMessage('Registration Successful! Please check your email for verification. You need to verify your account before you can login. If you don\'t see the email in your inbox, please check your spam folder.');
        
                setTimeout(() => {
                    setAlertMessage('');
                    navigate('/auth-signin-basic');
                }, 60000); // 1 minute before redirecting
            } else {
                console.log("Vendor already exists. Showing alert message...");

                // Vendor already exists, inform the user
                setAlertType('danger');
                setAlertMessage('An account already exists with this email. Please sign in using Google.');
            }
        } catch (error:any) {
            console.error('Google signup error:', error.message);
            setAlertType('danger');
            setAlertMessage('An error occurred during signup. Please try again later.');
        }
    };    
    
    return (
        <React.Fragment>
            <section className="auth-page-wrapper position-relative bg-light min-vh-100 d-flex align-items-center justify-content-between">
                <div className="auth-header position-fixed top-0 start-0 end-0 bg-body">
                    <Container fluid={true}>
                        <Row className="justify-content-between align-items-center">
                            <Col className="col-2">
                                <Link className="navbar-brand mb-2 mb-sm-0" to="https://www.aeropartx.com/#/dashboard">
                                    <img src={logoDark} className="card-logo card-logo-dark" alt="logo dark" height="22" />
                                    <img src={logoLight} className="card-logo card-logo-light" alt="logo light" height="22" />
                                </Link>
                            </Col>
                            <Col className="col-auto">
                                <ul className="list-unstyled hstack gap-2 mb-0">
                                    <li className="me-md-3">
                                        <Link to="#" className="text-body fw-medium fs-15">Become a Buyer</Link>
                                    </li>
                                    <li className="d-none d-md-block">
                                        <Link to="https://www.aeropartx.com/#/auth-signup-basic" className="btn custom-soft-primary-btn" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="align-middle" />Sign Up
                                        </Link>
                                    </li>
                                    <li className="d-none d-md-block">
                                        <Link to="https://www.aeropartx.com/#/auth-signin-basic" className="btn custom-soft-secondary-btn" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="align-middle" />Sign In
                                        </Link>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="w-100">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={6}>
                                <div className="auth-card mx-lg-3">
                                    <Card className="border-0 mb-0">
                                        <div className="custom-bg-primary border-0 px-5 py-3 rounded-top">
                                            <h1 className="text-white text-capitalize lh-base fw-lighter text-center">Get started as a Vendor</h1>
                                        </div>
                                        <Card.Body>
                                            <p className="text-muted fs-15">Create an AeroPartX account now</p>
                                            <div className="p-2">

                                                {errorFromServer && <Alert variant="danger">{errorFromServer}</Alert>}

                                                {alertMessage && <Alert variant={alertType}>{alertMessage}</Alert>}

                                                {success && <Alert variant="success">Registration successful! Please check your email for verification. You need to verify your account before you can login. If you don't see the email in your inbox, please check your spam folder.</Alert>}

                                                <Form className="needs-validation" action="#" onSubmit={(e) => { e.preventDefault(); validation.handleSubmit(); return false; }}>

                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="useremail">Email <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="email" id="useremail" placeholder="Enter email address"
                                                            name="email"
                                                            className="form-control"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.email || ""}
                                                            isInvalid={
                                                                validation.touched.email && validation.errors.email ? true : false
                                                            }
                                                        />
                                                        {validation.touched.email && validation.errors.email ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.email}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="username">Full Name <span className="text-danger">*</span></Form.Label>
                                                        <Form.Control type="text" id="username" placeholder="Enter full name"
                                                            name="username"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.username || ""}
                                                            isInvalid={
                                                                validation.touched.username && validation.errors.username ? true : false
                                                            }
                                                        />
                                                        {validation.touched.username && validation.errors.username ? (
                                                            <Form.Control.Feedback type="invalid"><div>{validation.errors.username}</div></Form.Control.Feedback>
                                                        ) : null}
                                                    </div>

                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="password-input">Password</Form.Label>
                                                        <div className="position-relative auth-pass-inputgroup">
                                                            <Form.Control type={!passwordShow ? "password" : "text"} className="pe-5 password-input" placeholder="Enter password" id="password-input"
                                                                name="password"
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.password || ""}
                                                                isInvalid={
                                                                    validation.touched.password && validation.errors.password ? true : false
                                                                }
                                                            />
                                                            <Button 
                                                                variant='link' 
                                                                className={`btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon ${validation.touched.password && validation.errors.password ? 'me-3' : ''}`} 
                                                                type="button" 
                                                                id="password-addon" 
                                                                onClick={() => setPasswordShow(!passwordShow)}
                                                            >
                                                                <i className="ri-eye-fill align-middle"></i>
                                                            </Button>

                                                            {validation.touched.password && validation.errors.password ? (
                                                                <Form.Control.Feedback type="invalid"><div>{validation.errors.password}</div></Form.Control.Feedback>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <div className="mb-4">
                                                        <p className="mb-0 fs-12 text-muted fst-italic">By registering you agree to the AeroPartX <Link to="https://aeropartx.com/terms-conditions" className="text-primary text-decoration-underline fst-normal fw-medium">Terms & Conditions</Link></p>
                                                    </div>

                                                    <div className="mt-4">
                                                        <Button variant='primary' className="w-100 custom-primary-btn" type="submit" disabled={loader}> {loader && <Spinner size="sm" animation="border" className="me-2" />} Sign Up</Button>
                                                    </div>

                                                    <div className="mt-4 text-center">
                                                        <div className="signin-other-title">
                                                            <h5 className="fs-13 mb-4 title text-muted">Create account with</h5>
                                                        </div>

                                                        <GoogleLoginUnavailableAlert/>

                                                        <div className="pt-2 hstack gap-2 justify-content-center">
                                                            <GoogleLogin
                                                                onSuccess={credentialResponse => {
                                                                    const credential = credentialResponse.credential;
                                                                    if (credential) {
                                                                        const credentialResponseDecoded = jwtDecode(credential);
                                                                        console.log(credentialResponseDecoded);
                                                                        handleGoogleSignup(credentialResponseDecoded);
                                                                    } else {
                                                                        console.error('Credential is undefined');
                                                                    }             
                                                                }}
                                                                onError={() => {
                                                                    console.log('Login Failed');
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                            <div className="mt-4 text-center">
                                                <p className="mb-0">Already have an account ? <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Sign In </Link> </p>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <footer className="footer">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center">
                                        <p className="mb-0 text-muted">©
                                            {(new Date().getFullYear())} AeroPartX. Site by <a href="https://cloudcraves.com" target="_blank" rel="noopener noreferrer">CloudCrave</a>
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </footer>
                </div>
            </section>
        </React.Fragment>
    );
};

export default Register;
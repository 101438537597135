import React, { useEffect, useState } from 'react';
import { Accordion, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { fetchCategoryList, CategoryList } from '../../../Common/data/category';

interface ProductCategoryFilterProps {
  onCategoryChange: (categories: string[]) => void;
  clearAllFilters: (event: React.MouseEvent<HTMLAnchorElement>) => void; // Add the clearAllFilters prop
}

const ProductCategoryFilter: React.FC<ProductCategoryFilterProps> = ({ onCategoryChange, clearAllFilters }) => {
  const [categories, setCategories] = useState<CategoryList[]>([]);
  const [selectedCategories, setSelectedCategories] = useState<string[]>([]);

  useEffect(() => {
    async function fetchData() {
      try {
        const categoryList = await fetchCategoryList();
        console.log('Fetched categories:', categoryList);
        setCategories(categoryList);
      } catch (error: any) {
        console.error('Error fetching category list:', error.message);
      }
    }

    fetchData();
  }, []);

  const handleCategoryClick = (catName: string) => {
    if (selectedCategories.includes(catName)) {
      setSelectedCategories((prevCategories) => prevCategories.filter((category) => category !== catName));
    } else {
      setSelectedCategories((prevCategories) => [...prevCategories, catName]);
    }
  };

  useEffect(() => {
    console.log('Selected categories:', selectedCategories);
    onCategoryChange(selectedCategories);
  }, [selectedCategories, onCategoryChange]);

  const categoryItems = categories.map((category) => (
    <li key={category.id}>
      <Link
        to="#"
        className={`d-flex py-1 align-items-center ${selectedCategories.includes(category.catName) ? 'active' : ''}`}
        onClick={() => handleCategoryClick(category.catName)}
      >
        <div className="flex-grow-1">
          <h6 className="mb-0 listname">{category.catName}</h6>
        </div>
        {selectedCategories.includes(category.catName) && <span className="badge rounded-pill bg-primary">Active</span>}
      </Link>
    </li>
  ));

  return (
    <React.Fragment>
      <Accordion flush defaultActiveKey="0" className="filter-accordion">
        <Card.Body className="border-bottom">
          <div>
            <p className="text-muted text-uppercase fs-13 mb-3">Products</p>
            <ul className="list-unstyled mb-0 filter-list">
              {categoryItems}
            </ul>
          </div>
        </Card.Body>
      </Accordion>
    </React.Fragment>
  );
};

export default ProductCategoryFilter;

import React, { useState } from 'react';
import { Alert, Button, Card, Col, Container, Form, Row, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from "react-redux";
import { createSelector } from 'reselect';
import { sendEmail } from 'Common/data/sendemail';
import { checkVendorExists } from 'Common/data/checkvendorexists'; 
import { getVendorByEmail } from 'Common/data/getvendorbyemail';

// Import Images
import logoDark from 'assets/images/logo-dark.png';
import logoLight from 'assets/images/logo-light.png';
import img1 from 'assets/images/auth/img-1.png';

import withRouter from 'Common/withRouter';

// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";

import { userForgetPassword } from "slices/thunk";

const ForgotPassword = () => {
    document.title = "Forget Password | AeroPartX";

    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: '',
        },
        validationSchema: Yup.object({
            email: Yup.string().email("Invalid email address").required("Please Enter Your Email"),
        }),
        onSubmit: async (values) => {
            try {
                setIsLoading(true);

                // Check if vendor exists
                const vendorExists = await checkVendorExists(values.email);

                if (!vendorExists.exists) {
                    setIsLoading(false);
                    setErrorMessage('Email does not exist.');
                    setShowSuccessAlert(false);
                    setShowErrorAlert(true);
                    return;
                }

                const vendorDetails = await getVendorByEmail(values.email);

                if (vendorDetails.status === 0) {
                    // Display bootstrap alert asking user to check for email from AeroPartX to verify account
                    setIsLoading(false);
                    setErrorMessage('Please check for an email from AeroPartX to verify your account before you can change your password.');
                    setShowSuccessAlert(false);
                    setShowErrorAlert(true);
                } else if (vendorDetails.status === 2) {
                    // Display bootstrap alert asking user to send admin a message
                    setIsLoading(false);
                    setErrorMessage('Your account is inactive. Please send a message to the admin to unblock at aeropartx.com/#/contact');
                    setShowSuccessAlert(false);
                    setShowErrorAlert(true);
                } else if (vendorDetails.status === 1) {
                    // Vendor exists, proceed with sending forgot password email
                    const sendForgotPasswordEmail = async (email: string) => {
                        try {
                            const emailData = {
                                email: email,
                                subject: "Forgot Password",
                                body: `Click here to reset your password: <a href="https://vendor.aeropartx.com/#/auth-pass-change-basic?email=${encodeURIComponent(email)}" style="color: #007bff; text-decoration: none; font-weight: bold;">Reset Password</a>`,
                            };

                            console.log('Email Data:', emailData);

                            const response = await sendEmail(emailData);

                            console.log('Forgot password email sent successfully:', response);
                            if (response.message === "Successful") {
                                setShowSuccessAlert(true);
                            }
                        } catch (error) {
                            console.error('Error sending forgot password email:', error);
                        } finally {
                            setIsLoading(false);
                        }
                    };

                await sendForgotPasswordEmail(values.email);
                }

            } catch (error) {
                console.error('Error sending forgot password email:', error);
            }
        }
    });

    const selectProperties = createSelector(
        (state) => state.ForgetPassword,
        (forgetPassword: any) => ({
            forgetError: forgetPassword.forgetError,
            forgetSuccessMsg: forgetPassword.forgetSuccessMsg,
        })
    );

    const { forgetError, forgetSuccessMsg } = useSelector(selectProperties);

    return (
        <React.Fragment>
            <section className="auth-page-wrapper position-relative bg-light min-vh-100 d-flex align-items-center justify-content-between">
                <div className="auth-header position-fixed top-0 start-0 end-0 bg-body">
                    <Container fluid={true}>
                        <Row className="justify-content-between align-items-center">
                            <Col className="col-2">
                                <Link className="navbar-brand mb-2 mb-sm-0" to="/">
                                    <img src={logoDark} className="card-logo card-logo-dark" alt="logo dark" height="22" />
                                    <img src={logoLight} className="card-logo card-logo-light" alt="logo light" height="22" />
                                </Link>
                            </Col>
                            <Col className="col-auto">
                                <ul className="list-unstyled hstack gap-2 mb-0">
                                    <li className="me-md-3">
                                        <Link to="#" className="text-body fw-medium fs-15">Become a Buyer</Link>
                                    </li>
                                    <li className="d-none d-md-block">
                                        <Link to="https://www.aeropartx.com/#/auth-signup-basic" className="btn custom-soft-primary-btn" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="align-middle" />Sign up
                                        </Link>
                                    </li>
                                    <li className="d-none d-md-block">
                                        <Link to="https://www.aeropartx.com/#/auth-signin-basic" className="btn custom-soft-secondary-btn" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="align-middle" />Sign In
                                        </Link>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="w-100">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={6}>
                                <div className="auth-card mx-lg-3">
                                    <Card className="border-0 mb-0">
                                        <div className="custom-bg-primary border-0 px-5 py-3 rounded-top">
                                            <h1 className="text-white text-capitalize lh-base fw-lighter text-center">Forgot Password?</h1>
                                        </div>
                                        <Card.Body>
                                            <p className="text-muted fs-15">Enter your email and instructions will be sent to you!</p>

                                            <div className="p-2">
                                                {showErrorAlert && (
                                                    <Alert variant="danger" style={{ marginTop: "13px" }}>
                                                        {errorMessage}
                                                    </Alert>
                                                )}
                                                {forgetError && forgetError ? (
                                                    <Alert variant="danger" style={{ marginTop: "13px" }}>
                                                        {forgetError}
                                                    </Alert>
                                                ) : null}
                                                {showSuccessAlert && (
                                                    <Alert variant="success" style={{ marginTop: "13px" }}>
                                                        An email has been sent to your address. Please check your inbox to reset your password.
                                                    </Alert>
                                                )}

                                                <Form onSubmit={validation.handleSubmit}>
                                                    <div className="mb-4">
                                                        <Form.Label htmlFor="email">Email</Form.Label>
                                                        <Form.Control
                                                            type="email"
                                                            id="email"
                                                            placeholder="Enter your email"
                                                            name="email"
                                                            className="form-control"
                                                            onChange={validation.handleChange}
                                                            onBlur={validation.handleBlur}
                                                            value={validation.values.email}
                                                            isInvalid={!!(validation.touched.email && validation.errors.email)}
                                                        />
                                                        {validation.touched.email && validation.errors.email && (
                                                            <Form.Control.Feedback type="invalid">
                                                                {validation.errors.email}
                                                            </Form.Control.Feedback>
                                                        )}
                                                    </div>

                                                    <div className="text-center mt-4">
                                                        <Button variant='primary' className="w-100 custom-primary-btn" type="submit" disabled={isLoading}>
                                                            {isLoading ? <Spinner animation="border" size="sm" /> : 'Send Reset Link'}
                                                        </Button>
                                                    </div>
                                                </Form>
                                            </div>
                                            <div className="mt-4 text-center">
                                                <p className="mb-0">Wait, I remember my password... <Link to="/login" className="fw-semibold text-primary text-decoration-underline"> Click here </Link> </p>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <footer className="footer">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center">
                                        <p className="mb-0 text-muted">©
                                            {(new Date().getFullYear())} AeroPartX. Site by <a href="https://cloudcraves.com" target="_blank" rel="noopener noreferrer">CloudCrave</a>
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </footer>
                </div>
            </section>
        </React.Fragment>
    );
}

export default withRouter(ForgotPassword);

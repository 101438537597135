import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Alert, Button, Card, Col, Container, OverlayTrigger, ProgressBar, Row, Tooltip } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Navigation, Thumbs } from "swiper/modules";
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import { fetchProductDetails } from '../../../Common/data/productoverview';

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// Import Images
import productsImg31 from "../../../assets/images/products/img-124.jpg";
import productsImg32 from "../../../assets/images/products/img-125.jpg";
import productsImg33 from "../../../assets/images/products/img-126.jpg";
import productsImg34 from "../../../assets/images/products/img-127.jpg";

import avatar1 from "../../../assets/images/users/avatar-1.jpg";
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar5 from "../../../assets/images/users/avatar-5.jpg";
import avatar8 from "../../../assets/images/users/avatar-8.jpg";

// SwiperCore.use([FreeMode, Navigation, Thumbs]);

const OrdersOverviewCustom = () => {

    document.title = "Product Overview | AeroPartX";

    const [thumbsSwiper, setThumbsSwiper] = useState<any>(null);
    const { productId } = useParams<{ productId: string }>();
    const [productDetails, setProductDetails] = useState<any>(null);

    useEffect(() => {
        async function fetchData() {
            try {
                if (!productId) {
                    // Handle the case when productId is undefined
                    return;
                }

                const productDetailsData = await fetchProductDetails(productId);
                setProductDetails(productDetailsData);
                console.log('Product Details:', productDetailsData);
            } catch (error: any) {
                // Handle errors
                console.error('Error:', error.message);
            }
        }

        fetchData();
    }, [productId]);

    // Format the date
    const formattedDate = productDetails ? new Date(productDetails.created).toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'short',
        day: 'numeric',
    }) : '';


    //tooltip
    const renderTooltip = (props: any) => (
        <Tooltip id="button-tooltip" {...props}>
            Click to view
        </Tooltip>
    );

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumb title="Overview" pageTitle="Product" />
                    <Row className="gx-lg-4">
                        <Col xl={4} lg={8} className="mx-auto">
                            <Row className="sticky-side-div">
                                <Col lg={2}>

                                <Swiper 
                                        onSwiper={setThumbsSwiper} 
                                        direction={"vertical"} 
                                        slidesPerView={4}
                                        freeMode={true} 
                                        modules={[FreeMode, Navigation, Thumbs]}
                                        watchSlidesProgress={true} 
                                        spaceBetween={10} 
                                        className="productSwiper productswiper-2 mb-3 mb-lg-0"
                                    >
                                        {/* thumbsSlider */}
                                        <div className="swiper-wrapper product-wrapper">
                                            {Array.isArray(productDetails?.productImage)
                                                ? productDetails.productImage.map((image: string, index: number) => (
                                                    <SwiperSlide key={index}>
                                                        <div className="product-thumb rounded cursor-pointer">
                                                            <img src={image} alt={`Product Image ${index + 1}`} className="img-fluid" />
                                                        </div>
                                                    </SwiperSlide>
                                                ))
                                                : (
                                                    <SwiperSlide>
                                                        <div className="product-thumb rounded cursor-pointer">
                                                            <img
                                                                src={productDetails?.productImage}
                                                                alt="Product Image"
                                                                className="img-fluid"
                                                            />
                                                        </div>
                                                    </SwiperSlide>
                                                )
                                            }
                                        </div>

                                    </Swiper>

                                </Col>
                                <Col lg={10}>
                                    <div className="bg-light rounded-2 position-relative ribbon-box overflow-hidden">

                                        <Swiper 
                                            navigation={true} 
                                            modules={[FreeMode, Navigation, Thumbs]} 
                                            thumbs={{ swiper: thumbsSwiper }} 
                                            className="productSwiper2">
                                            <div className="swiper-wrapper">
                                                {/* Check if productDetails is available before rendering the image */}
                                                {productDetails && (
                                                    <SwiperSlide>   
                                                        <img src={productDetails.productImage} alt={productDetails.productName} className="img-fluid" />
                                                    </SwiperSlide>
                                                )}
                                            </div>
                                        </Swiper>

                                    </div>
                                </Col>
                            </Row>
                        </Col>

                        <Col xl={8}>
                            <div className="mt-5 mt-xl-0">
                                <div className="d-flex">
                                    <div className="flex-grow-1">
                                        <h4> {productDetails?.productName} </h4>
                                        <div className="hstack gap-3 flex-wrap">
                                            <div className="text-muted">Published : <span className="text-body fw-medium"> {formattedDate} </span></div>
                                        </div>
                                    </div>
                                </div>

                                <div className="d-flex flex-wrap gap-2 align-items-center mt-3">
                                    <div className="text-muted fs-16">
                                        <span className="mdi mdi-star text-warning"></span>
                                        <span className="mdi mdi-star text-warning"></span>
                                        <span className="mdi mdi-star text-warning"></span>
                                        <span className="mdi mdi-star text-warning"></span>
                                        <span className="mdi mdi-star text-warning"></span>
                                    </div>
                                    <div className="text-muted">( Product Rating )</div>
                                </div>

                                <div className="mt-4 text-muted">
                                    <h5 className="fs-15">Description :</h5>
                                    <p>{productDetails?.productDescription}</p>
                                    
                                    <h6 className="fs-15 mb-3">Compatibility :</h6>
                                    <ul className="list-unstyled vstack gap-2">
                                        <li>
                                            <i className="bi bi-check2-circle me-2 align-middle text-success" />
                                            Aircraft Model : <b>{productDetails?.productModel}</b>
                                        </li>
                                        <li>
                                            <i className="bi bi-check2-circle me-2 align-middle text-success" />
                                            Year of Manufacture: <b>{productDetails?.productYear}</b>
                                        </li>
                                    </ul>
                                    <h6 className="fs-15 mb-3">Documentation :</h6>
                                    <ul className="list-unstyled vstack gap-2">
                                        <li>
                                            <i className="bi bi-check2-circle me-2 align-middle text-success" />
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={renderTooltip}
                                            >
                                                <Link to='#'> Quality Control Documentation</Link>
                                            </OverlayTrigger>
                                        </li>
                                        <li>
                                            <i className="bi bi-check2-circle me-2 align-middle text-success" />
                                            <OverlayTrigger
                                                placement="top"
                                                overlay={renderTooltip}
                                            >
                                                <Link to='#'> Maintenance Documentation</Link>
                                            </OverlayTrigger>
                                        </li>
                                    </ul>
                                </div>

                                

                                <h5 className="fs-15">Product Details:</h5>
                                <div className="table-responsive">
                                    <table className="table table-sm table-borderless align-middle description-table">
                                        <tbody>
                                            <tr>
                                                <th>Condition</th>
                                                <td> {productDetails?.type} </td>
                                            </tr>
                                            <tr>
                                                <th>FAA</th>
                                                <td>Approved</td>
                                            </tr>
                                            <tr>
                                                <th>EASA</th>
                                                <td>Certified</td>
                                            </tr>
                                            <tr>
                                                <th>ISO</th>
                                                <td>9001:2015</td>
                                            </tr>
                                            <tr>
                                                <th>Location</th>
                                                <td> {productDetails?.city} </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>

                                <div>
                                    <div className="d-flex flex-wrap gap-4 justify-content-between align-items-center mt-4">
                                        <div className="flex-shrink-0">
                                            <h5 className="fs-15 mb-3 fw-medium">Total Rating's</h5>
                                            <h2 className="fw-bold mb-3">10.14k</h2>
                                            <p className="text-muted mb-0">Growth in reviews on this year</p>
                                        </div>
                                        <hr className="vr" />
                                        <div className="flex-shrink-0">
                                            <h5 className="fs-15 mb-3 fw-medium">Average Rating</h5>
                                            <h2 className="fw-bold mb-3">5.6 <span className="fs-16 align-middle text-warning ms-2">
                                                <i className="ri-star-fill"></i>
                                                <i className="ri-star-fill"></i>
                                                <i className="ri-star-fill"></i>
                                                <i className="ri-star-fill"></i>
                                                <i className="ri-star-half-fill"></i>
                                            </span></h2>
                                            <p className="text-muted mb-0">Average rating on this year</p>
                                        </div>
                                        <hr className="vr" />
                                        <div className="flex-shrink-0 w-xl">
                                            <Row className="align-items-center g-3 align-items-center mb-2">
                                                <Col className="col-auto">
                                                    <div>
                                                        <h6 className="mb-0 text-muted fs-13"><i className="ri-star-fill text-warning me-1 align-bottom"></i>5</h6>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <ProgressBar variant="primary" className="progress-sm" now={50.16} />
                                                </Col>
                                                <Col className="col-auto">
                                                    <div>
                                                        <h6 className="mb-0 text-muted fs-13">2758</h6>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="align-items-center g-3 mb-2">
                                                <Col className="col-auto">
                                                    <div>
                                                        <h6 className="mb-0 text-muted fs-13"><i className="ri-star-fill text-warning me-1 align-bottom"></i>4</h6>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <ProgressBar variant="success" className="progress-sm" now={29.32} />
                                                </Col>
                                                <Col className="col-auto">
                                                    <div>
                                                        <h6 className="mb-0 text-muted fs-13">1063</h6>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="align-items-center g-3 mb-2">
                                                <Col className="col-auto">
                                                    <div>
                                                        <h6 className="mb-0 text-muted fs-13"><i className="ri-star-fill text-warning me-1 align-bottom"></i>3</h6>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <ProgressBar variant="info" className="progress-sm" now={18.12} />
                                                </Col>
                                                <Col className="col-auto">
                                                    <div>
                                                        <h6 className="mb-0 text-muted fs-13">997</h6>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="align-items-center g-3 mb-2">
                                                <Col className="col-auto">
                                                    <div>
                                                        <h6 className="mb-0 text-muted fs-13"><i className="ri-star-fill text-warning me-1 align-bottom"></i>2</h6>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <ProgressBar variant="secondary" className="progress-sm" now={4.98} />
                                                </Col>
                                                <Col className="col-auto">
                                                    <div>
                                                        <h6 className="mb-0 text-muted fs-13">227</h6>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row className="align-items-center g-3">
                                                <Col className="col-auto">
                                                    <div>
                                                        <h6 className="mb-0 text-muted fs-13"><i className="ri-star-fill text-warning me-1 align-bottom"></i>2</h6>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <ProgressBar variant="danger" className="progress-sm" now={7.42} />
                                                </Col>
                                                <Col className="col-auto">
                                                    <div>
                                                        <h6 className="mb-0 text-muted fs-13">408</h6>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                    <SimpleBar className="mt-4" data-simplebar="init" style={{ maxHeight: "350px" }}>
                                        <div className="d-flex p-3 border-bottom border-bottom-dashed">
                                            <div className="flex-shrink-0 me-3">
                                                <img className="avatar-xs rounded-circle" src={avatar5} alt="" />
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="d-flex mb-3">
                                                    <div className="flex-grow-1">
                                                        <div>
                                                            <h6 className="mb-0">Donald Risher</h6>
                                                        </div>
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                        <p className="mb-0 text-muted"><i className="ri-calendar-event-fill me-2 align-middle"></i>Oct 28, 2023</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h5 className="lh-base fs-17">Prompt and Friendly Communication</h5>
                                                    <p className="mb-0">
                                                        " I reached out to the seller with some inquiries about the avionics panel, and I was pleasantly surprised by their prompt and friendly communication. It's reassuring to deal with a seller who is responsive and willing to assist. "
                                                    </p>
                                                </div>
                                                <div className="d-flex mt-4">
                                                    <div className="flex-shrink-0 me-3">
                                                        <img className="avatar-xs rounded-circle" src={avatar1} alt="" />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div className="d-flex mb-3">
                                                            <div className="flex-grow-1">
                                                                <div>
                                                                    <h6 className="mb-2">Jansh Brown</h6>
                                                                    <p className="mb-0 text-muted fs-14">Vendor</p>
                                                                </div>
                                                            </div>
                                                            <div className="flex-shrink-0">
                                                                <p className="mb-0 text-muted"><i className="ri-calendar-event-fill me-2 align-middle"></i>Feb 04, 2023</p>
                                                            </div>
                                                        </div>
                                                        <p className="mb-0">
                                                            Thank You
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex p-3 border-bottom border-bottom-dashed">
                                            <div className="flex-shrink-0 me-3">
                                                <img className="avatar-xs rounded-circle" src={avatar3} alt="" />
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="d-flex mb-3">
                                                    <div className="flex-grow-1">
                                                        <div>
                                                            <h6 className="mb-0">Richard Smith</h6>
                                                        </div>
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                        <p className="mb-0 text-muted"><i className="ri-calendar-event-fill me-2 align-middle"></i>Dec 10, 2022</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h5 className="lh-base fs-17">Extensive Compatibility Data</h5>
                                                    <p className="mb-0">
                                                        " I appreciate the comprehensive compatibility data provided by the seller. It's evident that they've done their homework. This makes it much easier for buyers like me to ensure that the part is a perfect fit for our aircraft. Great job! "
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="d-flex p-3">
                                            <div className="flex-shrink-0 me-3">
                                                <img className="avatar-xs rounded-circle" src={avatar8} alt="" />
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="d-flex mb-3">
                                                    <div className="flex-grow-1">
                                                        <div>
                                                            <h6 className="mb-0">Pauline Moll</h6>
                                                        </div>
                                                    </div>
                                                    <div className="flex-shrink-0">
                                                        <p className="mb-0 text-muted"><i className="ri-calendar-event-fill me-2 align-middle"></i>Jan 28, 2023</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <h5 className="lh-base fs-17">Impressed with the Quality</h5>
                                                    <p className="mb-0">
                                                        "I recently came across this aircraft engine listed by the seller, and I must say I'm thoroughly impressed with the quality. The detailed description and the provided images are a testament to the seller's commitment to transparency. Looking forward to a potential deal! "
                                                    </p>
                                                </div>
                                                <div className="d-flex mt-4">
                                                    <div className="flex-shrink-0 me-3">
                                                        <img className="avatar-xs rounded-circle" src={avatar1} alt="" />
                                                    </div>
                                                    <div className="flex-grow-1">
                                                        <div className="d-flex mb-3">
                                                            <div className="flex-grow-1">
                                                                <div>
                                                                    <h6 className="mb-2">Jansh Brown</h6>
                                                                    <p className="mb-0 text-muted fs-14">Vendor</p>
                                                                </div>
                                                            </div>
                                                            <div className="flex-shrink-0">
                                                                <p className="mb-0 text-muted"><i className="ri-calendar-event-fill me-2 align-middle"></i>Aug 16, 2022</p>
                                                            </div>
                                                        </div>
                                                        <p className="mb-0">
                                                            Thank You
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </SimpleBar>
                                    <Card className="p-4">
                                        <h5 className="fs-18 mb-3">Add a Review</h5>
                                        <div>
                                            <form action="#" className="form">
                                                <div className="d-flex align-items-center mb-3">
                                                    <h6 className="fs-14 mb-0">Your rating:</h6>
                                                </div>
                                                <div className="mb-3">
                                                    <input className="form-control" name="your-name" placeholder="Title" type="text" />
                                                </div>
                                                <div className="mb-3">
                                                    <textarea className="form-control" name="your-commemt" placeholder="Enter your comments &amp; reviews" rows={4}></textarea>
                                                </div>
                                                <div className="text-end">
                                                    <Button variant="primary" className="btn-hover custom-primary-btn" type="submit" value="Submit">Send Review <i className="ri-send-plane-2-line align-bottom ms-1"></i></Button>
                                                </div>
                                            </form>
                                        </div>
                                    </Card>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
};

export default OrdersOverviewCustom;
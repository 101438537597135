// Import necessary components and functions
import CustomDropdownToggle from 'Common/CustomDropdownToggle';
import React, { useEffect, useState } from 'react';
import { Card, Col, Dropdown, Row } from 'react-bootstrap';
import { TopCategoriesChart } from './DashboardCharts';
import { RecentOrders, fetchRecentOrdersList } from "../../Common/data/dashboard";

const TopCategories = () => {
    const [mostAppearedCategories, setMostAppearedCategories] = useState<{ name: string; count: number }[]>([]);
    const [categoryCount, setCategoryCount] = useState<{ [key: string]: number }>({});
    const [percentages, setPercentages] = useState<{ name: string; percentage: number }[]>([]);
    const [totalCount, setTotalCount] = useState<number>(0);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const orderList = await fetchRecentOrdersList();
                const countedCategories = countProductCategories(orderList);

                // Log category names and counts together
                const categoryData = Object.keys(countedCategories).map(category => ({ name: category.trim(), count: countedCategories[category] }));

                // Sort categories by count in descending order
                const sortedCategories = categoryData.sort((a, b) => b.count - a.count);

                // Get the top 3 categories
                const topCategoriesData = sortedCategories.slice(0, 3);

                // Calculate count for "Others" category
                const othersCount = sortedCategories.slice(3).reduce((sum, category) => sum + category.count, 0);

                // Add "Others" category to the top categories
                topCategoriesData.push({ name: 'Others', count: othersCount });

                console.log('Category Names and Counts:', topCategoriesData);

                // Calculate percentages
                const totalItemCount = topCategoriesData.reduce((sum, category) => sum + category.count, 0);

                const percentages = topCategoriesData.map(category => ({
                    name: category.name,
                    percentage: totalItemCount === 0 ? 0 : (category.count / totalItemCount) * 100,
                }));

                console.log('Percentages:', percentages);

                setMostAppearedCategories(topCategoriesData);
                setCategoryCount(countedCategories);
                setPercentages(percentages);
                setTotalCount(totalItemCount);
            } catch (error:any) {
                console.error('Error fetching recent orders:', error.message);
            }
        };
        fetchData();
    }, []);

    const countProductCategories = (orderList: RecentOrders[]) => {
        const countedCategories: { [key: string]: number } = {};
    
        orderList.forEach(order => {
            const categories = order.catName.split(','); // Use catName instead of catID
    
            categories.forEach((category: string) => {
                category = category.trim();
    
                if (countedCategories[category]) {
                    countedCategories[category]++;
                } else {
                    countedCategories[category] = 1;
                }
            });
        });
    
        return countedCategories;
    };
    

    const getTopCategories = (countedCategories: { [key: string]: number }, topCount: number) => {
        return Object.keys(countedCategories)
            .sort((a, b) => countedCategories[b] - countedCategories[a])
            .slice(0, topCount);
    };

    return (
        <React.Fragment>
             <Col xxl={3} lg={6}>
                <Card className="card-height-100">
                    <Card.Header className="align-items-center d-flex">
                        <h4 className="card-title mb-0 flex-grow-1">Your Highest-Selling Category</h4>
                        {!mostAppearedCategories.length ? null : (
                            <div className="flex-shrink-0">
                                <Dropdown className="flex-shrink-0">
                                    <Dropdown.Toggle as={CustomDropdownToggle} href="#" className="text-reset dropdown-btn">
                                        <span className="text-muted">Report<i className="mdi mdi-chevron-down ms-1"></i></span>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className="dropdown-menu dropdown-menu-end">
                                        <Dropdown.Item className="dropdown-item" href="#">Download Report</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        )}
                    </Card.Header>
            
                    <Card.Body>
                        <div id="multiple_radialbar" dir="ltr">
                            <TopCategoriesChart 
                                dataColors='["--tb-primary", "--tb-danger", "--tb-success", "--tb-secondary"]' 
                                percentages={percentages}
                                totalCount={totalCount}
                            />
                        </div>

                        <Row className="g-3">
                            {mostAppearedCategories.map((category, index) => (
                                <Col md={6} key={index}>
                                    <Card className="text-center border-dashed mb-0">
                                        <Card.Body>
                                            <h6 className="fs-16">{category.count}</h6>
                                            <i className={`bi bi-square-fill ${getColorClass(index)} me-1 fs-11`}></i> {category.name}
                                        </Card.Body>
                                    </Card>
                                </Col>
                            ))}
                        </Row>
                    </Card.Body>
                </Card> 
            </Col>
        </React.Fragment>
    );
}

export default TopCategories;

function getColorClass(index: number) {
    const colors = ['text-primary', 'text-danger', 'text-success', 'text-secondary'];
    return colors[index % colors.length];
}

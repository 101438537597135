import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../helpers/api_helper';

export interface EmailResponse {
    data: any;
    success: boolean;
    message: string;
    // Add any additional fields you expect in the response
}

async function sendEmail(emailData: any): Promise<EmailResponse> {
    const apiUrl = '/Utilities/SendEmail';

    try {
        const response: AxiosResponse<EmailResponse> = await axiosInstance.post(apiUrl, emailData);

        // Log the entire response data
        console.log('Email Response:', response.data);

        // Return the response data
        return response.data;
    } catch (error: any) {
        console.error('Error sending email:', error.message);
        // Handle errors here
        throw error;
    }
}

export { sendEmail };

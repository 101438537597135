import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Container, Row, Alert } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import { Link, useParams, useNavigate } from 'react-router-dom'; 
import { addProductImage } from 'Common/data/addproductimage';
import ProgressBar from '../CreateProduct/ProgressBar';

const CreateProductSecond = () => {
  document.title = 'Create Product | AeroPartX';

  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const { productID } = useParams<{ productID: string }>();
  const [activeStep, setActiveStep] = useState(2);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [imagePreviews, setImagePreviews] = useState<string[]>([]);
  const [error, setError] = useState<string>('');

  const navigate = useNavigate();

  useEffect(() => {
    console.log('Selected Files:', selectedFiles);
    // Generate image previews when files are selected
    generateImagePreviews(selectedFiles);
  }, [selectedFiles]);

  // Function to read selected files and generate image previews
  const generateImagePreviews = (files: File[]) => {
    const imageUrls: string[] = [];
    const reader = new FileReader();
    for (const file of files) {
      reader.onload = (event: ProgressEvent<FileReader>) => {
        if (event.target) {
          const imageUrl = event.target.result;
          imageUrls.push(imageUrl as string);
          setImagePreviews([...imageUrls]);
        }
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files) {
      const fileList = Array.from(files);
      setSelectedFiles(fileList);
    }
  };

  const uploadImages = async (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
  
    try {
      // Check if no files are selected
      if (selectedFiles.length === 0) {
        setError('Please select at least one image.');
        return;
      }

      setIsLoading(true); // Start loading

      const formData = new FormData();

      // Append productID to the FormData only if it is defined
      if (productID !== undefined) {
        formData.append('productID', productID);
      } else {
        // Handle the case where productID is undefined
        console.error('productID is undefined');
        return;
      }
  
      // Append files to the FormData
      for (const file of selectedFiles) {
        formData.append('product', file);
      }
  
      const apiResponse = await addProductImage(formData);

      console.log('API Response:', apiResponse);

      if (apiResponse && apiResponse.data && apiResponse.data.ResponseCode === '00') {
        setShowSuccessAlert(true); // Show success alert

        // Extract the ProductID from the API response
        const sameProductID = productID;

        // After 5 seconds, redirect to the next page
        setTimeout(() => {
          navigate(`/product-create-third/${sameProductID}`);
        }, 3000);
      }
  
    } catch (error: any) {
      console.error('Error uploading images:', error.message);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb title="Create Product" pageTitle="Products" />
          <ProgressBar activeStep={activeStep}/>
          <form id="createproduct-form" autoComplete="off" className="needs-validation" noValidate>
            <Row>
              <Col xl={9} lg={8}>
                <Card>
                  <Card.Header>
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-sm">
                          <div className="avatar-title rounded-circle custom-bg-light custom-text-primary fs-20">
                            <i className="bi bi-images"></i>
                          </div>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h5 className="card-title mb-1">Part Image</h5>
                        <p className="text-muted mb-0">Add part images.</p>
                      </div>
                    </div>
                  </Card.Header>
                  <Card.Body>
                    <div className="my-dropzone">
                      <input
                        type="file"
                        accept="image/*"
                        multiple
                        onChange={handleFileChange}
                      />
                    </div>
                    {/* Display error message */}
                    {error && (
                      <Alert variant="danger" className="mt-3">
                        {error}
                      </Alert>
                    )}
                    {/* Display image previews */}
                    <div className="mt-3">
                      {imagePreviews.map((imageUrl, index) => (
                        <img
                          key={index}
                          src={imageUrl}
                          alt={`Preview ${index + 1}`}
                          className="img-fluid rounded"
                          style={{ maxHeight: '200px', maxWidth: '100%', marginRight: '10px' }}
                        />
                      ))}
                    </div>
                    <div className="error-msg mt-1">Please add part document.</div>
                  </Card.Body>
                </Card>

                {/* Success alert */}
                {showSuccessAlert && (
                  <Alert variant="success" onClose={() => setShowSuccessAlert(false)} dismissible>
                    Product image added successfully!
                  </Alert>
                )}

                <div className="text-end mb-3">
                  <Button
                    variant="success"
                    type="submit"
                    className="w-sm custom-primary-btn"
                    onClick={uploadImages}
                    disabled={isLoading}
                  >
                    {isLoading ? (
                      <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    ) : (
                      'Submit'
                    )}
                  </Button>
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateProductSecond;

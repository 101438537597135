// api.js
import { axiosInstance } from '../../helpers/api_helper';

const changePassword = async (formData: any) => {
  try {
    console.log('FormData in changePassword:', formData);

    const response = await axiosInstance.patch('Vendor/UpdateVendorPassword', formData);

    console.log('API Response from changePassword:', response);

    if (response.data.status === '00') {
      return response.data;
    } else {
      console.error('Unexpected API Response:', response);
      throw new Error('Error changing password');
    }
  } catch (error) {
    console.error('Error in changePassword:', error);
    throw error;
  }
};

export { changePassword };

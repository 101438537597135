import React, { useEffect } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import logoDark from 'assets/images/logo-dark.png';
import logoLight from 'assets/images/logo-light.png';
import { confirmVendor } from 'Common/data/confirmvendor'; 

const EmailVerification = () => {
    const { verificationToken } = useParams();
    document.title = "Email Verification | AeroPartX";

    useEffect(() => {
        const confirmVendorRequest = async () => {
            try {
                if (!verificationToken) {
                    console.error('Verification token is undefined.');
                    return;
                }
                const response = await confirmVendor(verificationToken); // Call the confirmVendor function with the verificationToken
                console.log('Vendor confirmation response:', response);
                // You can handle the confirmation response here, such as displaying a message to the user
            } catch (error) {
                console.error('Error confirming vendor:', error);
                // Handle errors here
            }
        };
    
        if (verificationToken) {
            console.log('Verification token:', verificationToken); // Log verification token
            confirmVendorRequest();
        }
    }, [verificationToken]);
    

    return (
        <React.Fragment>
            <section className="auth-page-wrapper position-relative bg-light min-vh-100 d-flex align-items-center justify-content-between">
                <div className="auth-header position-fixed top-0 start-0 end-0 bg-body">
                    <Container fluid={true} className="container-fluid">
                        <Row className="justify-content-between align-items-center">
                            <Col className="col-2">
                                <Link className="navbar-brand mb-2 mb-sm-0" to="/">
                                    <img src={logoDark} className="card-logo card-logo-dark" alt="logo dark" height="22"/>
                                    <img src={logoLight} className="card-logo card-logo-light" alt="logo light" height="22"/>
                                </Link>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="w-100">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={6}>
                                <div className="auth-card mx-lg-3">
                                    <Card className="border-0 mb-0">
                                        <div className="custom-bg-primary border-0 px-5 py-3 rounded-top">
                                            <h1 className="text-white text-capitalize lh-base fw-lighter text-center">Email Verification</h1>
                                        </div>
                                        <Card.Body className="text-center">
                                            <div className="avatar-sm mx-auto mb-4">
                                                <div className="avatar-title bg-success-subtle text-success fs-20 rounded">
                                                    <i className="bi bi-check-circle"></i>
                                                </div>
                                            </div>
                                            
                                            <p className="text-muted fs-15">Your email has been successfully verified.</p>

                                            <div>
                                                <Link to="/login" className="btn custom-primary-btn w-100">Back to Sign In</Link>
                                            </div>
                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </section>
        </React.Fragment>
    );
}

export default EmailVerification;

import React from 'react';

const GoogleLoginUnavailableAlert = () => {
    return (
        <div className="alert alert-warning" role="alert">
            Google authentication is currently unavailable.
        </div>
    );
};

export default GoogleLoginUnavailableAlert;

import React from 'react';

interface ProgressBarProps {
  activeStep: number;
}

const ProgressBar: React.FC<ProgressBarProps> = ({ activeStep }) => {
  let width = '0%';
  let secondButtonClassName = 'btn-light'; // Default class for the second button
  let thirdButtonClassName = 'btn-light'; // Default class for the third button

  if (activeStep === 1) {
    width = '0%';
  } else if (activeStep === 2) {
    width = '50%';
    secondButtonClassName = 'btn-primary'; // Remove btn-primary class when activeStep is 2
  } else if (activeStep === 3) {
    width = '100%';
    secondButtonClassName = 'btn-primary'; // Add btn-primary class when activeStep is 3
    thirdButtonClassName = 'btn-primary'; // Change class for the third button when activeStep is 3
  }

  return (
    <div className="position-relative m-4">
      <div className="progress" style={{ height: '1px' }}>
        <div className="progress-bar" role="progressbar" style={{ width }} aria-valuenow={activeStep === 1 ? 0 : (activeStep === 2 ? 50 : 100)} aria-valuemin={0} aria-valuemax={100}></div>
      </div>
      <button type="button" className="position-absolute top-0 start-0 translate-middle btn btn-sm btn-primary rounded-pill" style={{ width: '2rem', height: '2rem' }}>1</button>
      <button type="button" className={`position-absolute top-0 start-50 translate-middle btn btn-sm rounded-pill ${secondButtonClassName}`} style={{ width: '2rem', height: '2rem' }}>2</button>
      <button type="button" className={`position-absolute top-0 start-100 translate-middle btn btn-sm rounded-pill ${thirdButtonClassName}`} style={{ width: '2rem', height: '2rem' }}>3</button>
    </div>
  );
};

export default ProgressBar;

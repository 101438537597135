import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Form, Button, ListGroup } from 'react-bootstrap';
import BreadCrumb from 'Common/BreadCrumb';
import { fetchAllConversationsApi } from '../../Common/data/allconversation';
import { sendMessageApi } from '../../Common/data/sendMessage';
import { fetchConversationDetailsApi } from '../../Common/data/conversation';
import img1 from 'assets/images/users/user-dummy-img.jpg';

interface Message {
  ID: number;
  sender: string;
  message: string;
  date: string;
}

interface Conversation {
  conversationID: string;
  date: string | number | Date;
  ID: number;
  receiver: string;
  receiverName: string;
  messages: Message[];
  sender: string;
  senderName: string;
  message: string
}

const Chat = () => {
  document.title = 'Chat | AeroPartX Vendor';

  const [conversations, setConversations] = useState<Conversation[]>([]);
  const [selectedConversation, setSelectedConversation] = useState<Conversation | null>(null);
  const [message, setMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [conversationDetails, setConversationDetails] = useState<Conversation[]>([]);

  const vendorID = localStorage.getItem('authUser')
    ? JSON.parse(localStorage.getItem('authUser')!).vendorID
    : '';

  const fetchConversations = async () => {
    try {
      const fetchedConversations = await fetchAllConversationsApi();
      console.log('Fetched Conversations:', fetchedConversations);

      // Filter conversations where either receiver or sender matches the vendorID
      const filteredConversations = fetchedConversations.filter(
        (conversation: Conversation) =>
          conversation.receiver === vendorID || conversation.sender === vendorID
      );

      const conversationMap = new Map<string, Conversation>();

      filteredConversations.forEach((conversation: Conversation) => {
        const existingConversation = conversationMap.get(String(conversation.conversationID));
        if (
          !existingConversation ||
          new Date(conversation.date).getTime() > new Date(existingConversation.date).getTime()
        ) {
          conversationMap.set(String(conversation.conversationID), conversation);
        }
      });

      const sortedConversations = Array.from(conversationMap.values());

      const sortedAndFilteredConversations = sortedConversations.sort(
        (a: Conversation, b: Conversation) =>
          new Date(b.date).getTime() - new Date(a.date).getTime()
      );

      setConversations(sortedAndFilteredConversations);
    } catch (error) {
      console.error('Error fetching conversations:', error);
    }
  };

const exportChat = () => {
  if (!selectedConversation || !conversationDetails) {
    console.error('No conversation or details available for export.');
    return;
  }

  const formattedChat = conversationDetails.map((detail: Conversation) => {
    return `${detail.senderName} to ${detail.receiverName}: ${detail.message}\n`;
  }).join('');

  console.log('Formatted Chat:', formattedChat);

  const blob = new Blob([formattedChat], { type: 'text/plain' });

  const a = document.createElement('a');
  const url = URL.createObjectURL(blob);
  a.href = url;
  a.download = 'chat_export.txt';

  document.body.appendChild(a);
  a.click();

  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};

  const fetchDetailsAndMessages = async (conversation: Conversation) => {
    try {
      setSelectedConversation(conversation);

      const details = await fetchConversationDetailsApi(conversation.conversationID);
      console.log('Fetched Conversation Details:', details);

      setConversationDetails(details);
    } catch (error) {
      console.error('Error fetching conversation details:', error);
    }
  };

  const sendMessage = async () => {
    try {
      if (!selectedConversation) {
        setErrorMessage('Select a chat on the left to send a message.');
        return;
      }

      if (!message.trim()) {
        setErrorMessage('Please enter a message.');
        return;
      }

      const { conversationID, receiver, receiverName, senderName } = selectedConversation!;
      const sender = vendorID || '';
      const messageText = message;

      // Use the conversationID from the selectedConversation
      await sendMessageApi(sender, messageText, conversationID, senderName, receiver, receiverName);

      // Fetch updated conversation details after sending the message
      const updatedDetails = await fetchConversationDetailsApi(conversationID);
      setConversationDetails(updatedDetails);

      setMessage('');
    } catch (error) {
      console.error('Error sending message:', error);
    }
  };

  useEffect(() => {
    fetchConversations();
  }, [vendorID]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Chat" pageTitle="Chat" />
          <Row>
            <Col md={4} xs={12}>
              <ListGroup>
                {conversations.length === 0 && <ListGroup.Item>No conversations available.</ListGroup.Item>}
                {conversations.map((conversation) => (
                  <ListGroup.Item
                    key={conversation.ID}
                    action
                    onClick={() => fetchDetailsAndMessages(conversation)}
                    active={selectedConversation === conversation}
                  >
                    <Row className="align-items-center">
                      <Col xs={2}>
                        <img src={img1} alt="User" className="rounded-circle" height="40" />
                      </Col>
                      <Col xs={10}>
                        <div>
                          <strong>{conversation.senderName}:</strong>
                        </div>
                        <div className="text-ellipsis">{conversation.message}</div>
                        <div>
                          {new Date(conversation.date).toLocaleDateString('en-US', {
                            month: 'short',
                            day: 'numeric',
                            year: 'numeric',
                          })}
                        </div>
                      </Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            </Col>
            <Col md={8} xs={12}>
              <div
                style={{
                  height: '400px',
                  overflowY: 'auto',
                  border: '1px solid #ddd',
                  padding: '10px',
                  borderRadius: '5px',
                  display: 'flex',
                  flexDirection: 'column-reverse', // Reverse the order of messages
                }}
              >
                {selectedConversation ? (
                  <React.Fragment>
                    {conversationDetails && conversationDetails.length > 0 ? (
                      <div>
                        {conversationDetails.map((detail: Conversation) => (
                          <React.Fragment key={detail.ID}>
                            <div
                              style={{
                                display: 'flex',
                                justifyContent: detail.sender === vendorID ? 'flex-end' : 'flex-start',
                                marginBottom: '16px',
                              }}
                            >
                              <div
                                style={{
                                  backgroundColor: detail.sender === vendorID ? '#007bff' : '#f0f0f0',
                                  color: detail.sender === vendorID ? '#fff' : '#000',
                                  padding: '16px',
                                  borderRadius: '5px',
                                  maxWidth: '70%', // Set a maximum width for the chat bubble
                                }}
                              >
                                <p style={{ margin: '0', wordWrap: 'break-word', whiteSpace: 'pre-wrap' }}>
                                  {detail.message}
                                </p>
                              </div>
                            </div>
                            {/* Date display in the desired format */}
                            <p
                              style={{
                                fontSize: '12px',
                                margin: '0',
                                textAlign: detail.sender === vendorID ? 'right' : 'left',
                                marginBottom: '16px',
                              }}
                            >
                              {new Date(detail.date).toLocaleString('en-US', {
                                month: 'short',
                                day: 'numeric',
                                year: 'numeric',
                                hour: 'numeric',
                                minute: 'numeric',
                                hour12: true,
                              })}
                            </p>
                          </React.Fragment>
                        ))}
                      </div>
                    ) : (
                      <div>No conversation details available.</div>
                    )}
                  </React.Fragment>
                ) : (
                  <div>Select a conversation to view messages.</div>
                )}
              </div>

              <div className="message-input my-3">
                <Form>
                  <Row className="g-2">
                    <Col xs={9}>
                      <Form.Control
                        type="text"
                        placeholder="Enter Message..."
                        value={message}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                      {errorMessage && <div className="text-danger">{errorMessage}</div>}
                    </Col>
                    <Col xs={3}>
                      <Button variant="info" onClick={sendMessage}>
                        Send
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </div>

              <div className="export-button mb-3">
                <Button variant="success" onClick={exportChat}>
                  Export Chat
                </Button>
              </div>
                
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Chat;

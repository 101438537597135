import { axiosInstance } from '../../helpers/api_helper';

const updateProductStatus = async (formData: any) => {
  try {
    console.log('FormData in updateProductStatus:', formData);

    const response = await axiosInstance.patch('/Products/Update', formData);

    console.log('API Response from updateProductStatus:', response); // Log the response

    if (response.data.status === '00') {
      // Handle success, if needed
      return response.data;
    } else {
      // Handle error
      throw new Error('Error updating product status');
    }
  } catch (error: any) {
    // Handle network or other errors
    throw new Error(`Error: ${error.message}`);
  }
};

export { updateProductStatus };

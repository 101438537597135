import React , { useState } from 'react';
import { Button, Card, Col, Container, Form, Modal, Row } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import CountUp from "react-countup";
import ListViewTable from './listViewTable';
import Flatpickr from "react-flatpickr";

const OrdersListView = () => {

    document.title = "List - Orders | AeroPartX";

    const [searchInput, setSearchInput] = useState('');

    const [modal_AddOrderModals, setmodal_AddOrderModals] = useState<boolean>(false);
    function tog_AddOrderModals() {
        setmodal_AddOrderModals(!modal_AddOrderModals);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <Breadcrumb title="List View" pageTitle="Orders" />

                    <Row id="orderList">
                        <Col lg={12}>
                            <Card>
                                <Card.Body>
                                    <Row className="g-3">
                                        <Col xxl={4} sm={6}>
                                            <div className="search-box">
                                                <input 
                                                    type="text" 
                                                    className="form-control search" 
                                                    placeholder="Search for order ID, customer or something..." 
                                                    value={searchInput}
                                                    onChange={(e) => setSearchInput(e.target.value)}
                                                />
                                                <i className="ri-search-line search-icon"></i>
                                            </div>
                                        </Col>

                                        <Col xxl={2} sm={6}>
                                            <div>
                                                <Flatpickr
                                                className="form-control flatpickr-input"
                                                placeholder='Select Date'
                                                    options={{
                                                    dateFormat: "d M, Y",
                                                    }}
                                                />
                                            </div>
                                        </Col>

                                        <Col xxl={2} sm={12}>
                                            <div className="hstack gap-2">
                                                <Button variant='primary' className="w-100 custom-primary-btn">
                                                    <i className="bi bi-filter me-1"></i> Filter
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                            
                            {/* ListViewTable */}
                            <ListViewTable searchInput={searchInput}/>

                            <div className="noresult" style={{ display: "none" }}>
                                <div className="text-center py-4">
                                    <div className="avatar-md mx-auto mb-4">
                                        <div className="avatar-title bg-primary-subtle text-primary rounded-circle fs-24">
                                            <i className="bi bi-search"></i>
                                        </div>
                                    </div>
                                    <h5 className="mt-2">Sorry! No Result Found</h5>
                                    <p className="text-muted mb-0">We've searched more than 150+ Orders We did not find any orders for you search.</p>
                                </div>
                            </div>

                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment >
    );
}

export default OrdersListView;
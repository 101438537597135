import { axiosInstance } from '../../helpers/api_helper';

const fetchAllNationalitiesApi = async () => {
  try {
    const response = await axiosInstance.get('/Utilities/nationalities');
    return response.data;
  } catch (error) {
    console.error('Error fetching nationalities:', error);
    throw error;
  }
};

export { fetchAllNationalitiesApi };

import { axiosInstance } from '../../helpers/api_helper';

const fetchConversationDetailsApi = async (conversationID: string) => {
  try {
    const response = await axiosInstance.get(`/Chat/${conversationID}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching conversation details:', error);
    throw error;
  }
};

export { fetchConversationDetailsApi };

import { axiosInstance } from '../../helpers/api_helper';

const sendMessageApi = async (
  vendorID: string,
  message: string,
  conversationID: string,
  receiver: string,
  senderName: string,
  receiverName: string,
) => {
  try {
    const response = await axiosInstance.post('/Chat/continue', {
      sender: vendorID,
      receiver: receiver,
      message: message,
      conversationID: conversationID,
      senderName: senderName,
      receiverName: receiverName,
    });

    console.log('API Response from sendMessage:', response);

    // Return the message from the API response
    return response.data.message;
  } catch (error) {
    console.error('Error sending message:', error);
    throw error;
  }
};

export { sendMessageApi };

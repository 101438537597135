import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Container, Form, Row, Alert } from 'react-bootstrap';
import Breadcrumb from 'Common/BreadCrumb';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { fetchProductDetails } from '../../../Common/data/productoverview';
import { addProduct } from '../../../Common/data/addproduct';
import { CategoryList, fetchCategoryList } from '../../../Common/data/category';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import ProgressBar from './ProgressBar';

const CreateProduct = () => {
  document.title = 'Create Product | AeroPartX';

  const { productID } = useParams();
  const [editorContent, setEditorContent] = useState<string>('');
  const [categoryList, setCategoryList] = useState<CategoryList[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedYear, setSelectedYear] = useState<Date | null>(null); // Changed type to Date | null
  const [activeStep, setActiveStep] = useState(1);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  useEffect(() => {
    const fetchCategoryListData = async () => {
      try {
        const categoryData = await fetchCategoryList();
        setCategoryList(categoryData);
      } catch (error: any) {
        console.error('Error fetching product list:', error.message);
      }
    };

    fetchCategoryListData();
  }, []);

  const navigate = useNavigate();

  const initialValues = {
    name: '',
    description: '',
    model: '',
    category: '',
    condition: '',
    partnumber: '',
    productYear: '', // Added productYear to initialValues
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required('Please enter a part name.'),
    description: Yup.string()
      .test(
        'wordCount',
        'Description must have at least 3 words',
        (value) => (value ? value.split(' ').filter(Boolean).length >= 3 : true)
      ),
    //model: Yup.string().required('Please enter an aircraft model.'),
    category: Yup.string().required('Please select a part category.'),
    condition: Yup.string().required('Please select a part condition.'),
    partnumber: Yup.string().required('Please enter a part number.'),
    //productYear: Yup.string().required('Please select a year of manufacture.'),
  });

  const handleSubmit = async (values: any, { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }) => {
    setIsLoading(true);
    try {
      const formDataToSend = {
        name: values.name,
        description: values.description || 'Available on demand',
        model: values.model || 'Available on demand',
        category: values.category,
        productYear: selectedYear ? selectedYear.toLocaleDateString('en-GB', { month: '2-digit', year: 'numeric' }) : 'Available on demand',
        vendor: JSON.parse(localStorage.getItem('authUser') || '{}').vendorID || '',
        state: Number(values.condition),
        manufacturers: 'Not Updated',
        partnumber: values.partnumber,
        price: '0',
      };
  
      const apiResponse = await addProduct(formDataToSend);
  
      if (apiResponse && apiResponse.status === '00') {
        setShowSuccessAlert(true); // Show success alert
        const newProductID = apiResponse.ProductID;

        // After 3 seconds, redirect to the next page
        setTimeout(() => {
          navigate(`/product-create-second/${newProductID}`);
        }, 3000);
      } else {
        console.error('Error adding product:', apiResponse.message);
      }
    } catch (error: any) {
      console.error('Error:', error.message);
    } finally {
      setIsLoading(false);
      setSubmitting(false);
    }
  };  

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumb title="Create Product" pageTitle="Products" />
          <ProgressBar activeStep={activeStep}/>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, errors, touched, setFieldValue }) => (
              <FormikForm autoComplete="off" className="needs-validation" noValidate>
                <Row>
                  <Col xl={9} lg={8}>
                    <Card>
                      <Card.Header>
                        <div className="d-flex">
                          <div className="flex-shrink-0 me-3">
                            <div className="avatar-sm">
                              <div className="avatar-title rounded-circle custom-bg-light custom-text-primary fs-20">
                                <i className="bi bi-box-seam"></i>
                              </div>
                            </div>
                          </div>
                          <div className="flex-grow-1">
                            <h5 className="card-title mb-1">Part Information</h5>
                            <p className="text-muted mb-0">Fill all information below.</p>
                          </div>
                        </div>
                      </Card.Header>
                      <Card.Body>
                        <div className="mb-3">
                          <Form.Label htmlFor="product-title-input">Part Name</Form.Label>
                          <Field
                            type="text"
                            id="product-title-input"
                            name="name"
                            placeholder="Enter part name"
                            className={`form-control ${errors.name && touched.name ? 'is-invalid' : ''}`}
                          />
                          <ErrorMessage name="name" component="div" className="invalid-feedback" />
                        </div>
                        <div className="mb-3">
                          <Form.Label htmlFor="product-number-input">Part Number</Form.Label>
                          <Field
                            type="text"
                            id="product-number-input"
                            name="partnumber"
                            placeholder="Enter part number"
                            className={`form-control ${errors.partnumber && touched.partnumber ? 'is-invalid' : ''}`}
                          />
                          <ErrorMessage name="partnumber" component="div" className="invalid-feedback" />
                        </div>
                        <div className="mb-3">
                          <Form.Label>Part Description</Form.Label>
                          <Field
                            as="textarea"
                            id="product-description-textarea"
                            name="description"
                            rows={5}
                            placeholder="Enter part description"
                            className={`form-control ${errors.description && touched.description ? 'is-invalid' : ''}`}
                          />
                          <ErrorMessage name="description" component="div" className="invalid-feedback" />
                        </div>
                        <div>
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <Form.Label>Part Category</Form.Label>
                            </div>
                          </div>
                          <div>
                            <Field
                              as="select"
                              id="choices-category-input"
                              name="category"
                              className={`form-select ${errors.category && touched.category ? 'is-invalid' : ''}`}
                            >
                              <option value="">Select part category</option>
                              {categoryList.map((category) => (
                                <option key={category.id} value={category.catID}>
                                  {category.catName}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage name="category" component="div" className="invalid-feedback" />
                          </div>
                        </div>
                        <div className="mt-3">
                          <Form.Label htmlFor="product-aircraft-model-input">Aircraft Model</Form.Label>
                          <Field
                            type="text"
                            id="product-aircraft-model-input"
                            name="model"
                            placeholder="Enter aircraft model"
                            className={`form-control ${errors.model && touched.model ? 'is-invalid' : ''}`}
                          />
                          <ErrorMessage name="model" component="div" className="invalid-feedback" />
                        </div>
                        <div className="mt-3">
                          <Form.Label htmlFor="product-year-input">Date of Manufacture</Form.Label>
                        </div>
                        <div className="mb-3">
                          <DatePicker
                            id="product-year-input"
                            selected={selectedYear}
                            onChange={(date: Date) => {
                              setSelectedYear(date); // Changed to set selectedYear directly
                              setFieldValue('productYear', date.toLocaleDateString('en-GB', { month: '2-digit', year: 'numeric' }));
                            }}
                            dateFormat="MM/yyyy"
                            showMonthYearPicker
                            maxDate={new Date()}
                            className={`form-control ${errors.productYear && touched.productYear ? 'is-invalid' : ''}`}
                          />
                          <ErrorMessage name="productYear" component="div" className="invalid-feedback" />
                        </div>

                        <div>
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <Form.Label>Condition</Form.Label>
                            </div>
                          </div>
                          <div>
                            <Field
                              as="select"
                              id="choices-condition-input"
                              name="condition"
                              className={`form-select ${errors.condition && touched.condition ? 'is-invalid' : ''}`}
                            >
                              <option value="">Select condition</option>
                              <option value="1">New</option>
                              <option value="2">OH</option>
                              <option value="3">Rep</option>
                              <option value="4">SV</option>
                              <option value="5">AR</option>
                            </Field>
                            <ErrorMessage name="condition" component="div" className="invalid-feedback" />
                          </div>
                        </div>
                      </Card.Body>
                    </Card>
                    {/* Success alert */}
                    {showSuccessAlert && (
                      <Alert variant="success" onClose={() => setShowSuccessAlert(false)} dismissible>
                        Product details submitted successfully!
                      </Alert>
                    )}
                    
                    <div className="text-end mb-3">
                      <Button
                        variant="success"
                        type="submit"
                        className="w-sm custom-primary-btn"
                        disabled={isSubmitting || isLoading}
                      >
                        {isLoading ? (
                          <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                        ) : (
                          "Submit"
                        )}
                      </Button>
                    </div>
                    
                  </Col>
                </Row>
              </FormikForm>
            )}
          </Formik>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateProduct;

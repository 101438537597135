import React, { useState, useEffect, useMemo } from 'react';
import { fetchProductList, ProductList } from '../../../Common/data/products';
import TableContainer from "Common/TableContainer";
import { profile } from "Common/data";
import { Dropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import moment from 'moment';

interface ProductTableProps {
    searchInput: string;
}

const ProductTable: React.FC<ProductTableProps> = ({ searchInput }) => {

  const handleValidDate = (date: any) => {
    const date1 = moment(new Date(date)).format("DD MMM Y");
    return date1;
  };

  const handleValidTime = (time: any) => {
    const time1 = new Date(time);
    const getHour = time1.getUTCHours();
    const getMin = time1.getUTCMinutes();
    const getTime = `${getHour}:${getMin}`;
    var meridiem = "";
    if (getHour >= 12) {
      meridiem = "PM";
    } else {
      meridiem = "AM";
    }
    const updateTime = moment(getTime, 'hh:mm').format('hh:mm') + " " + meridiem;
    return updateTime;
  };

    // State to store the product list data
    const [productList, setProductList] = useState<ProductList[]>([]);

    // Call the fetchProductList function inside useEffect
    useEffect(() => {
        const fetchProductListData = async () => {
            try {
                const productData = await fetchProductList();
                // Set the product list to the state
                setProductList(productData);
            } catch (error: any) {
                console.error('Error fetching product list:', (error as Error).message);
            }
        };

        // Call the fetchProductListData function
        fetchProductListData();
    }, []);

    const filteredProductList = useMemo(() => {
        return productList.filter(product =>
            product.productName.toLowerCase().includes(searchInput.toLowerCase())
        );
    }, [productList, searchInput]);

    const columns = useMemo(
        () => [
            {
                Header: (<div className="form-check"> <input className="form-check-input" type="checkbox" id="checkAll" value="option" /> </div>),
                Cell: (cellProps: any) => {
                    return (<div className="form-check"> <input className="form-check-input" type="checkbox" name="chk_child" defaultValue="option1" /> </div>);
                },
                id: '#',
            },
            {
                Header: "Product ID",
                accessor: (cellProps: any) => {
                    return (
                        <Link to="#" className="fw-medium">{cellProps.productID}</Link>
                    )
                },
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Part Number",
                accessor: "productPartNumber",
                disableFilters: true,
                filterable: true,
            },
            {
                Header: "Part Name",
                Cell: (product: any) => (
                    <>
                        <div className="d-flex align-items-center">
                            <div className="flex-shrink-0 me-3">
                            <div className="avatar-title bg-light rounded">
                                <img
                                    src={product.row.original.productImage}
                                    alt=""
                                    className="avatar-xs"
                                />
                            </div>
                            </div>
                            <div className="flex-grow-1">
                            <h5 className="fs-14 mb-1">
                                <Link
                                to="/apps-ecommerce-product-details"
                                className="d-block text-reset"
                                >
                                {" "}
                                {product.row.original.productName}
                                </Link>
                            </h5>
                            <p className="text-muted mb-0">
                                Category :{" "}
                                <span className="fw-medium">
                                {" "}
                                {product.row.original.catName}
                                </span>
                            </p>
                            </div>
                        </div>
                    </>
                ),
            },
            {
                Header: "Action",
                disableFilters: true,
                filterable: true,
                accessor: (cellProps: any) => {
                    return (
                        <React.Fragment>
                            <Dropdown className="text-center">
                                <Dropdown.Toggle href="#!" className="btn btn-soft-secondary btn-sm btn-icon dropdown arrow-none">
                                    <i className="mdi mdi-dots-horizontal" />
                                </Dropdown.Toggle>
                                <Dropdown.Menu as="ul" className="dropdown-menu-end">
                                    <li>
                                        <Dropdown.Item href="/orders-overview">
                                            <i className="ri-eye-fill align-bottom me-2 text-muted" /> View
                                        </Dropdown.Item>
                                    </li>
                                    <li>
                                        <Dropdown.Item href="#" className="remove-list">
                                            <i className="ri-delete-bin-fill align-bottom me-2 text-muted" />Delete
                                        </Dropdown.Item>
                                    </li>
                                </Dropdown.Menu>
                            </Dropdown>
                        </React.Fragment>
                    )
                },
            },
        ],
        []
    );

    return (
        <React.Fragment>
            <TableContainer
                columns={(columns || [])}
                data={(filteredProductList || [])}
                // isGlobalFilter={false}
                iscustomPageSize={false}
                isBordered={false}
                customPageSize={10}
                className="custom-header-css table align-middle table-nowrap"
                tableClass="table-centered align-middle table-nowrap mb-0"
                theadClass="text-muted table-light"
                SearchPlaceholder='Search Products...'
            />
        </React.Fragment>
    );
};

export default ProductTable;
import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Row, Alert, Spinner, Form } from 'react-bootstrap';
import { Formik, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup'; // Import Yup for validation
import Flatpickr from 'react-flatpickr';
import { fetchProfileDetails } from '../../../Common/data/profile';
import { updateVendor } from '../../../Common/data/updatevendor';
import { fetchAllNationalitiesApi } from 'Common/data/allNationalities';

interface Nationality {
  id: number;
  nationality: string;
}

const PersonalInformation = () => {
  const [profile, setProfile] = useState<any>(null);
  const [countries, setCountries] = useState<Nationality[]>([]);
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [alertMessage, setAlertMessage] = useState<string>('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const profileData = await fetchProfileDetails();
        console.log('Fetched profile data:', profileData); // Log the fetched profile data
        setProfile(profileData);
      } catch (error: any) {
        console.error('Error fetching profile details:', error.message);
      }
    };

    fetchData();

    const fetchNationalities = async () => {
      try {
        const nationalities = await fetchAllNationalitiesApi();
        setCountries(nationalities);
      } catch (error: any) {
        console.error('Error fetching nationalities:', error.message);
      }
    };

    fetchNationalities();
  }, []);

  useEffect(() => {
    console.log('Profile state:', profile); // Log the profile state
  }, [profile]);  

  const handleUpdateProfile = async (values: any) => {
    try {
      setLoading(true);

      const updatedData = {
        vendorName: values.userName,
        vendorPhone: values.phoneInput,
        city: 'Not Needed', // Fill city with "Not Needed"
        country: values.countryInput,
        vendorEmail: JSON.parse(localStorage.getItem('authUser') || '{}').vendorEmail || '',
        vendorAddress: 'Not Needed',
        vendorRCNo: 'Not Needed',
        vendorID: JSON.parse(localStorage.getItem('authUser') || '{}').vendorID || '',
        status: 1,
      };

      // Call the updateVendor function
      const response = await updateVendor(updatedData);

      if (response.status === '00') {
        setShowAlert(true);
        setAlertMessage('Profile updated successfully!');
      } else {
        setShowAlert(true);
        setAlertMessage(response.message || 'Failed to update profile');
      }
    } catch (error: any) {
      console.error('Error updating profile:', error.message);
      setShowAlert(true);
      setAlertMessage('An error occurred while updating profile');
    } finally {
      setLoading(false);
    }
  };

  // Define validation schema using Yup
  const validationSchema = Yup.object().shape({
    userName: Yup.string().required('User Name is required'),
    phoneInput: Yup.string().required('Phone Number is required'),
    countryInput: Yup.string().required('Country is required'),
  });

  return (
    <React.Fragment>
      <Row>
        <Col lg={4}>
          <h5 className="fs-16">Personal Information</h5>
        </Col>
        <Col lg={8}>
          <Card>
            <Card.Body>
              {showAlert && (
                <Alert variant={alertMessage.includes('successfully') ? 'success' : 'danger'} onClose={() => setShowAlert(false)} dismissible>
                  {alertMessage}
                </Alert>
              )}
              {profile ? ( // Check if profile data is available
                <Formik
                  initialValues={{
                    userName: profile.vendorName || '',
                    phoneInput: profile.vendorPhone || '',
                    countryInput: profile.country || '',
                  }}
                  onSubmit={(values) => {
                    handleUpdateProfile(values);
                  }}
                  validationSchema={validationSchema} // Assign validation schema
                >
                  {({ handleSubmit }) => (
                    <Form onSubmit={handleSubmit}>
                      <Row className="g-3">
                        <Col lg={4}>
                          <div>
                            <Form.Label 
                              htmlFor="userName"
                            >
                              User Name 
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Field 
                              type="text" 
                              id="userName" 
                              name="userName" 
                              placeholder="Enter your user name" 
                              className="form-control" 
                            />
                            <ErrorMessage name="userName" component="div" className="text-danger" />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div>
                            <Form.Label 
                              htmlFor="phoneInput"
                            >
                              Phone Number 
                              <span className="text-danger">*</span>
                            </Form.Label>
                            <Field 
                              type="text" 
                              id="phoneInput" 
                              name="phoneInput" 
                              placeholder="Enter phone number" 
                              className="form-control"
                            />
                            <ErrorMessage name="phoneInput" component="div" className="text-danger" />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="d-flex align-items-start">
                            <div className="flex-grow-1">
                              <Form.Label 
                                htmlFor="countryInput">Country 
                                <span className="text-danger">*</span>
                              </Form.Label>
                            </div>
                          </div>
                          <div className="position-relative">
                            <Field as="select" id="countryInput" name="countryInput" className="form-select custom-select">
                              <option value="">Select a Country</option>
                              {countries.map((nationality) => (
                                <option key={nationality.id} value={nationality.nationality}>
                                  {nationality.nationality}
                                </option>
                              ))}
                            </Field>
                            <ErrorMessage name="countryInput" component="div" className="text-danger" />
                            <span className="custom-select-arrow"></span>
                          </div>
                        </Col>

                        <Col lg={12}>
                          <div className="text-end">
                            <Button
                              variant="secondary"
                              type="submit"
                              className="custom-primary-btn"
                              disabled={loading}
                            >
                              {loading ? <Spinner animation="border" size="sm" /> : 'Update Profile'}
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Form>
                  )}
                </Formik>
              ) : (
                <Spinner animation="border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
              )}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default PersonalInformation;

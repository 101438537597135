import React, { useEffect, useState } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import BreadCrumb from 'Common/BreadCrumb';
import { TopSellingProductCharts } from './StatisticsCharts';
import { fetchRecentOrdersList, RecentOrders } from "../../Common/data/dashboard";

interface FilteredData {
  id: number;
  orderDate: string;
  catName: string;
  // Add other properties needed for filtering
}

const Statistics: React.FC = () => {
  document.title = "Statistics | AeroPartX";

  const [topSellingProductData, setTopSellingProductData] = useState<RecentOrders[]>([]);
  const [seriesData, setSeriesData] = useState<{ name: string; data: { month: string; year: number; count: number }[] }[]>([]);


  const fetchData = async () => {
    try {
      const data = await fetchRecentOrdersList();
      setTopSellingProductData(data);
  
      // Structure the data for filtering
      const filteredData: FilteredData[] = data.map(order => ({
        id: order.id,
        orderDate: order.orderDate,
        catName: order.catName,
        // Add other properties needed for filtering
      }));
  
      // Extract categories and count occurrences
      const categoryCount: { [category: string]: number } = {};
  
      filteredData.forEach(order => {
        const categoryName = order.catName;
  
        // Initialize the category count if not already initialized
        if (!categoryCount[categoryName]) {
          categoryCount[categoryName] = 0;
        }
  
        // Update the category count
        categoryCount[categoryName]++;
      });
  
      // Sort categories based on the total count
      const sortedCategories = Object.keys(categoryCount).sort((a, b) => categoryCount[b] - categoryCount[a]);
  
      // Take the top 4 categories
      const topCategories = sortedCategories.slice(0, 4);
  
      // Group the remaining categories into "Other"
      const otherCategories = sortedCategories.slice(4);
  
      // Create the series array with a modified TypeScript type
      const currentDate = new Date();
      const currentYear = currentDate.getFullYear();
      const currentMonth = currentDate.getMonth() + 1;
  
      const series: { name: string; data: { month: string; year: number; count: number }[] }[] = topCategories.map(category => {
        const categoryData = Array.from({ length: currentMonth }, (_, i) => {
          const month = i + 1;
          const filteredOrders = filteredData.filter(order => order.catName === category && new Date(order.orderDate).getMonth() + 1 === month && new Date(order.orderDate).getFullYear() === currentYear);
          return {
            month: new Date(currentYear, i, 1).toLocaleString('default', { month: 'short' }),
            year: currentYear,
            count: filteredOrders.length > 0 ? filteredOrders.reduce((total, order) => total + 1, 0) : 0,
          };
        });
  
        return {
          name: category, // Use actual category names as the name property
          data: categoryData,
        };
      });
  
      // Add "Other" category to series if applicable
      if (otherCategories.length) {
        const otherData = Array.from({ length: currentMonth }, (_, i) => {
          const month = i + 1;
          const filteredOrders = filteredData.filter(order => otherCategories.includes(order.catName) && new Date(order.orderDate).getMonth() + 1 === month && new Date(order.orderDate).getFullYear() === currentYear);
          const monthName = new Date(currentYear, i, 1).toLocaleString('default', { month: 'short' }); // Get short month name
          const monthCount = filteredOrders.length > 0 ? filteredOrders.reduce((total, order) => total + 1, 0) : 0;
  
          return { month: monthName, year: currentYear, count: monthCount };
        });
  
        series.push({
          name: 'Other',
          data: otherData,
        });
      }
  
      console.log('Series:', series);
      setSeriesData(series);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Statistics" pageTitle="More" />
          <Row>
            <Col xxl={8}>
              <Card>
                <Card.Header className='align-item-center d-sm-flex'>
                  <h5 className="card-title mb-sm-0 mt-1 flex-grow-1">Top Selling Product</h5>
                </Card.Header>
                <div className="card-body">
                  {seriesData && seriesData.length > 0 ? (
                    <TopSellingProductCharts
                      dataColors='["--tb-primary", "--tb-primary-rgb, 0.80", "--tb-primary-rgb, 0.70", "--tb-primary-rgb, 0.60", "--tb-primary-rgb, 0.50"]'
                      data={topSellingProductData}
                      seriesData={seriesData}
                    />
                  ) : (
                    <p>Nothing yet</p>
                  )}
                </div>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Statistics;

import { axiosInstance } from '../../helpers/api_helper';

interface CategoryList {
    id: number;
    catID: string;
    catName: string;
    created: string;
    status: number;
}

const baseURL = axiosInstance.defaults.baseURL;

async function fetchCategoryList(): Promise<CategoryList[]> {
    
    const apiUrl = `/Categories`;

    try {
        const response = await axiosInstance.get(apiUrl);

        // Log the entire response
        console.log('API Response:', response);
        console.log('API Response Data:', response.data);

        const categoryList: CategoryList[] = response.data;

        return categoryList;
    } catch (error: any) {
        console.error('Error:', error.message);
        // Handle errors here
        throw error; 
    }
}

export { fetchCategoryList, type CategoryList };
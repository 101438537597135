// Import necessary modules
import { AxiosResponse } from 'axios';
import { axiosInstance } from '../../helpers/api_helper';

interface Profile {
  id: number;
  vendorID: string;
  vendorName: string;
  vendorPhone: string;
  vendorEmail: string;
  vendorPassword: string; // Add this property if needed
  vendorAddress: string;
  country: number;
  city: string;
  vendorRCNo: string;
  logo: string;
  status: number;
}

interface ProductList {
  id: number;
  productID: string;
  productName: string;
  productDescription: string;
  productModel: string;
  productPartNumber: string;
  productCategories: string;
  productPrice: number;
  productManufacturers: string;
  productVendor: string;
  productImage: string;
  productStateType: number;
  productStatus: number;
  vendorID: string;
  vendorName: string;
  vendorPhone: string;
  vendorEmail: string;
  vendorAddress: string;
  country: number;
  city: string;
  vendorRCNo: string;
  logo: string;
  status: number;
  catID: string;
  catName: string;
  created: string;
  type: string;
  productCreated: string;
}

const authUser = localStorage.getItem('authUser');
const { vendorID } = authUser ? JSON.parse(authUser) : { vendorID: '' };

// Function to fetch profile details
async function fetchProfileDetails(): Promise<Profile> {
  const userId = '88884037d-g758-4fde-0004-2da4321bu2c3';
  const apiUrl = `/Vendor/Details/${vendorID}`;

  try {
    const response: AxiosResponse<Profile[]> = await axiosInstance.get(apiUrl);

    // Log the entire response
    console.log('Profile API Response:', response);
    console.log('Profile API Response Data:', response.data);

    // Assuming the API returns an array, take the first element
    const profile = response.data[0];

    return profile;
  } catch (error: any) {
    console.error('Error fetching profile details:', error.message);
    // Handle errors here
    throw error;
  }
}

async function fetchProductList(): Promise<ProductList[]> {
  const userId = '88884037d-g758-4fde-0004-2da4321bu2c3';
  const apiUrl = `/Vendor/Products/${vendorID}`;

  try {
    const response = await axiosInstance.get(apiUrl);

    // Log the entire response
    console.log('API Response:', response);
    console.log('API Response Data:', response.data);

    const productList: ProductList[] = response.data;

    // Log the number of products
    console.log('Number of products:', productList.length);

    return productList;
  } catch (error: any) {
    console.error('Error:', error.message);
    // Handle errors here
    throw error;
  }
}

export { fetchProfileDetails, fetchProductList };

import React, { useState, useEffect } from 'react';
import { Button, Card, Col, Container, Form, Row, Spinner, Alert } from 'react-bootstrap'; // Import Alert component
import { Link, useLocation } from 'react-router-dom';
import { getVendorByEmail } from 'Common/data/getvendorbyemail';
import { updatePassword } from 'Common/data/updatepassword';
import * as Yup from "yup";
import { useFormik } from "formik";

// Import Images
import logoDark from 'assets/images/logo-dark.png';
import logoLight from 'assets/images/logo-light.png';
import img1 from 'assets/images/auth/img-1.png';

const PasswordCreate = () => {
    document.title = "Change Password | AeroPartX";

    const [passwordShow, setPasswordShow] = useState(false);
    const [confirmPasswordShow, setConfirmPasswordShow] = useState(false);
    const [vendorID, setVendorID] = useState(null); // State to store vendor ID
    const [loading, setLoading] = useState(false); // State to control loading spinner
    const [passwordUpdated, setPasswordUpdated] = useState(false); // State to track if password has been updated successfully

    const { search } = useLocation(); 
    const queryParams = new URLSearchParams(search);
    const email = queryParams.get('email') || ''; // Default value or empty string

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            password: '',
            confirm_password: '',
        },
        validationSchema: Yup.object({
            password: Yup.string().required("Please Enter Your Password").matches(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"),
            confirm_password: Yup.string().required("Please Enter Your Confirm Password").oneOf([Yup.ref('password')], 'Passwords must match'),
        }),
        onSubmit: async (values) => {
            try {
                setLoading(true); // Show loading spinner

                // Call getVendorByEmail to fetch vendor data
                console.log('Fetching vendor data...');
                const vendorData = await getVendorByEmail(email);
                console.log('Vendor data:', vendorData);

                if (vendorData.vendorID) {
                    setVendorID(vendorData.vendorID); // Set vendorID from response

                    // Call updatePassword with vendorID and new password
                    console.log('Updating password...');
                    const response = await updatePassword({
                        vendorID: vendorData.vendorID,
                        password: values.password
                    });
                    console.log('Password update response:', response);
                    
                    // Handle success
                    console.log('Password updated successfully!');
                    setPasswordUpdated(true); // Set password updated state to true
                } else {
                    // Handle error, vendor ID not found
                    console.error('Vendor ID not found in response:', vendorData);
                }
            } catch (error:any) {
                console.error('Error updating password:', error.message);
                // Handle error
            } finally {
                setLoading(false); // Hide loading spinner
            }
        }
    });

    useEffect(() => {
        if (email) {
            console.log("Fetching data for email:", email);
            const fetchData = async () => {
                try {
                    const data = await getVendorByEmail(email);
                    console.log('Response from API:', data);
                } catch (error:any) {
                    console.error('Error:', error.message);
                }
            };
            fetchData();
        } else {
            console.log("Email parameter is undefined");
        }
    }, [email]);

    return (
        <React.Fragment>
            <section className="auth-page-wrapper position-relative bg-light min-vh-100 d-flex align-items-center justify-content-between">
                <div className="auth-header position-fixed top-0 start-0 end-0 bg-body">
                    <Container fluid={true} className="container-fluid">
                        <Row className="justify-content-between align-items-center">
                            <Col className="col-2">
                                <Link className="navbar-brand mb-2 mb-sm-0" to="/">
                                    <img src={logoDark} className="card-logo card-logo-dark" alt="logo dark" height="22" />
                                    <img src={logoLight} className="card-logo card-logo-light" alt="logo light" height="22" />
                                </Link>
                            </Col>
                            <Col className="col-auto">
                                <ul className="list-unstyled hstack gap-2 mb-0">
                                    <li className="me-md-3">
                                        <Link to="#" className="text-body fw-medium fs-15">Become a Buyer</Link>
                                    </li>
                                    <li className="d-none d-md-block">
                                        <Link to="#" className="btn custom-soft-primary-btn" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="align-middle" />Sign up
                                        </Link>
                                    </li>
                                    <li className="d-none d-md-block">
                                        <Link to="#" className="btn custom-soft-secondary-btn" data-bs-toggle="dropdown" aria-expanded="false">
                                            <i className="align-middle" />Log in
                                        </Link>
                                    </li>
                                </ul>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <div className="w-100">
                    <Container>
                        <Row className="justify-content-center">
                            <Col lg={6}>
                                <div className="auth-card mx-lg-3">
                                    <Card className="border-0 mb-0">
                                        <Card.Header className="custom-bg-primary border-0">
                                            <h1 className="text-white text-center fw-lighter">Create New Password</h1>
                                        </Card.Header>
                                        <Card.Body>
                                            <p className="text-muted fs-15">Your new password must be different from previously used passwords.</p>

                                            {/* Display success alert when password has been updated */}
                                            {passwordUpdated && (
                                                <Alert variant="success" className="mt-3">
                                                    Password updated successfully!
                                                </Alert>
                                            )}

                                            <div className="p-2">
                                                <Form onSubmit={validation.handleSubmit}>
                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="password-input">Password</Form.Label>
                                                        <div className="position-relative auth-pass-inputgroup">
                                                            <Form.Control type={passwordShow ? "text" : "password"} className="pe-5 password-input" placeholder="Enter password" id="password-input"
                                                                name="password"
                                                                autoComplete='off'
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.password || ""}
                                                                isInvalid={
                                                                    validation.touched.password && validation.errors.password ? true : false
                                                                }
                                                            />
                                                            <Button variant="link" className="btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></Button>
                                                            {validation.touched.password && validation.errors.password ? (
                                                                <Form.Control.Feedback type="invalid"><div>{validation.errors.password}</div></Form.Control.Feedback>
                                                            ) : null}
                                                        </div>
                                                        <div id="passwordInput" className="form-text">Your password must be 8-20 characters long.</div>
                                                    </div>

                                                    <div className="mb-3">
                                                        <Form.Label htmlFor="confirm-password-input">Confirm Password</Form.Label>
                                                        <div className="position-relative auth-pass-inputgroup mb-3">
                                                            <Form.Control type={confirmPasswordShow ? "text" : "password"} className="pe-5 password-input" placeholder="Confirm password" id="confirm-password-input"
                                                                name="confirm_password"
                                                                autoComplete='off'
                                                                onChange={validation.handleChange}
                                                                onBlur={validation.handleBlur}
                                                                value={validation.values.confirm_password || ""}
                                                                isInvalid={
                                                                    validation.touched.confirm_password && validation.errors.confirm_password ? true : false
                                                                }
                                                            />
                                                            <Button variant="link" className="btn-link position-absolute end-0 top-0 text-decoration-none text-muted password-addon" type="button" onClick={() => setConfirmPasswordShow(!confirmPasswordShow)}><i className="ri-eye-fill align-middle"></i></Button>
                                                            {validation.touched.confirm_password && validation.errors.confirm_password ? (
                                                                <Form.Control.Feedback type="invalid"><div>{validation.errors.confirm_password}</div></Form.Control.Feedback>
                                                            ) : null}
                                                        </div>
                                                    </div>

                                                    <div className="form-check form-check-primary">
                                                        <Form.Check type="checkbox" value="" id="auth-remember-check" />
                                                        <Form.Label htmlFor="auth-remember-check">Remember me</Form.Label>
                                                    </div>

                                                    <div className="mt-3">
                                                        <Button variant='primary' className="w-100 custom-primary-btn" type="submit">
                                                            {loading ? <Spinner animation="border" size="sm" className="me-2" /> : null}
                                                            Reset Password
                                                        </Button>
                                                    </div>

                                                </Form>
                                            </div>
                                            <div className="mt-4 text-center">
                                                <p className="mb-0">Wait, I remember my password... <Link to="/#/login" className="fw-semibold text-primary text-decoration-underline"> Click here </Link> </p>
                                            </div>

                                        </Card.Body>
                                    </Card>
                                </div>
                            </Col>
                        </Row>
                    </Container>

                    <footer className="footer">
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center">
                                        <p className="mb-0 text-muted">©
                                            {(new Date().getFullYear())} AeroPartX. Site by <a href="https://cloudcraves.com" target="_blank" rel="noopener noreferrer">CloudCrave</a>
                                        </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                    </footer>
                </div>
            </section>
        </React.Fragment>
    );
}

export default PasswordCreate;

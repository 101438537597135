import { axiosInstance } from '../../helpers/api_helper';

const updateProduct = async (formData: any) => {
  try {
    console.log('FormData in updateProduct:', formData);

    const response = await axiosInstance.patch('/Products/UpdateProduct', formData);

    console.log('API Response from updateProduct:', response);

    if (response.data.status === '00') {
      return response.data;
    } else {
      console.error('Unexpected API Response:', response);
      throw new Error('Error updating product');
    }
  } catch (error: any) {
    // Log the actual error details
    console.error('Error in updateProduct:', error);

    // Rethrow the error to maintain the error propagation
    throw error;
  }
};

export { updateProduct };

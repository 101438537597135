import React, { useMemo, useState, useEffect } from 'react';
import { Card, Col, Dropdown } from 'react-bootstrap';

//TableContainer
import TableContainer from "../../Common/TableContainer";
import { RecentOrders, fetchRecentOrdersList } from "../../Common/data/dashboard";
import { Link } from 'react-router-dom';
import CustomDropdownToggle from 'Common/CustomDropdownToggle';

const RecentOrdersComponent = () => {

  const [orders, setOrders] = useState<RecentOrders[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const orderList = await fetchRecentOrdersList();
        setOrders(orderList);
        setLoading(false);
      } catch (error:any) {
        console.error('Error fetching recent orders:', error.message);
        // Handle error as needed
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = { year: 'numeric', month: 'short', day: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleDateString('en-US', options);
    return formattedDate;
  };

  const columns = useMemo(
    () => [
      {
        Header: "Order ID",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps: any) => {
          return <Link to="#" className="fw-medium link-primary">{cellProps.orderID}</Link>;
        },
      },
      {
        Header: "Part Number",
        accessor: "productPartNumber",
        disableFilters: true,
        filterable: true,
      },
      {
        Header: "Part Name",
        accessor: "productName",
        disableFilters: true,
        filterable: true,
      },
      {
        Header: "Customer Name",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps: any) => {
          return (
            <React.Fragment>
              <div className="d-flex align-items-center">
                <div className="flex-shrink-0 me-2">
                  <img src={cellProps.productImage} alt="" className="avatar-xxs rounded-circle" />
                </div>
                <div className="flex-grow-1">{cellProps.fullName}</div>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Order Date",
        accessor: "orderDate",
        disableFilters: true,
        filterable: true,
        Cell: (cellProps: any) => {
          return <span>{formatDate(cellProps.value)}</span>;
        },
      },
      {
        Header: "Rating",
        disableFilters: true,
        filterable: true,
        accessor: (cellProps: any) => {
          return (<React.Fragment>
            {cellProps.rating} <i className="bi bi-star-half ms-1 text-warning fs-12"></i>
          </React.Fragment>)
        }
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Col xxl={12}>
        <Card>
          <Card.Header className="align-items-center d-flex">
            <h4 className="card-title mb-0 flex-grow-1">Recent Orders</h4>
          </Card.Header>

          {/* <Card.Body> */}
          <TableContainer
            columns={(columns || [])}
            data={(orders || [])}
            isGlobalFilter={false}
            iscustomPageSize={false}
            isBordered={false}
            customPageSize={6}
            className="custom-header-css table-card"
            tableClass="table-centered align-middle table-nowrap mb-0"
            theadClass="text-muted table-light"
            SearchPlaceholder='Search Products...'
          />
          {/* </Card.Body> */}
        </Card>
      </Col>

    </React.Fragment>
  );
};

export default RecentOrdersComponent;
import { axiosInstance } from '../../helpers/api_helper';
import axios from 'axios';

type ApiResponse = {
  ResponseCode?: string;
  imageUrl?: string;
  data?: {
    ResponseCode?: string;
  };
};

const addProductImage = async (formData: FormData): Promise<ApiResponse> => {
  try {
    console.log('FormData in addProductImage:', formData);

    //const response = await axiosInstance.post('/Products/UploadProductImage', formData, {

    const response = await axios.post('https://aeropartx.hatini.com.ng/Products/UploadProductImage', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    console.log('API Response from addProductImage:', response);

    //if (response.data && response.data.ResponseCode === '00') {
    if (response.data && response.data.data.ResponseCode === '00') {
      return response.data;
    } else {
      console.error('Error adding product. Server response:', response);

      // Log additional details about the response
      console.error('Response data:', response.data);
      console.error('Response status:', response.status);

      throw new Error(`Error adding product. Server responded with status ${response.status}`);
    }
  } catch (error: any) {
    // Handle network or other errors
    console.error('Error in addProductImage:', error.message || 'Undefined error');
    throw new Error(`Error: ${error.message || 'Undefined error'}`);
  }
};

export { addProductImage };

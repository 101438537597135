//Include Both Helper File with needed methods
import { getFirebaseBackend } from "../../../helpers/firebase_helper";
import {
  postFakeLogin,
  postJwtLogin,
} from "../../../helpers/fakebackend_helper";

import { getVendorByEmail } from 'Common/data/getvendorbyemail';
import { checkVendorExists } from "Common/data/checkvendorexists";

import { loginSuccess, logoutUserSuccess, apiError, reset_login_flag } from './reducer';

export const loginUser = (user: any, router: any) => async (dispatch: any) => {

  try {
    let response;
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      let fireBaseBackend = getFirebaseBackend();
      response = fireBaseBackend.loginUser(
        user.email,
        user.password
      );
    } else if (process.env.REACT_APP_DEFAULTAUTH === "jwt") {
      response = postJwtLogin({
        email: user.email,
        password: user.password
      });

    } else if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
      response = postFakeLogin({
        email: user.email,
        password: user.password,
      });
    }

    const data = await response;
    console.log('Login Response:', data);

    if (data) {
      localStorage.setItem("authUser", JSON.stringify(data.data))

      if (process.env.REACT_APP_DEFAULTAUTH === "fake") {
        if (data.data.vendorEmail) {
          dispatch(loginSuccess(data.data));
          router("/");
        } else {
          dispatch(apiError(data)); // Pass the entire response object to apiError
        }
      } else {
        dispatch(loginSuccess(data));
        router("/")
      }
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const logoutUser = () => async (dispatch: any) => {
  try {
    localStorage.removeItem("authUser");
    const fireBaseBackend = getFirebaseBackend();
    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const response = fireBaseBackend.logout;
      dispatch(logoutUserSuccess(response));
    } else {
      dispatch(logoutUserSuccess(true));
    }

  } catch (error) {
    dispatch(apiError(error));
  }
};

export const socialLogin = (email: string, history: any) => async (dispatch: any) => {
  try {
    let response;

    if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
      const fireBaseBackend = getFirebaseBackend();
      // Assuming socialLoginUser method accepts the email parameter
      response = await fireBaseBackend.socialLoginUser(email);
    }
    // Add other social login methods if needed

    console.log("Social login response:", email);

    if (email) {
      // Check if the vendor exists
      const vendorExistsResponse = await checkVendorExists(email);

      if (vendorExistsResponse.exists) {
        // Vendor exists, proceed with fetching vendor details
        const vendorDetailsResponse = await getVendorByEmail(email);

        if (vendorDetailsResponse) {
          // Vendor details fetched successfully
          const vendorDetails = vendorDetailsResponse;

          // Store vendor details in local storage
          localStorage.setItem('authUser', JSON.stringify(vendorDetails));

          // Dispatch action to handle successful login
          dispatch(loginSuccess(vendorDetailsResponse));

          // Redirect or navigate the user to the desired page after successful sign-in
          history('/dashboard');
        } else {
          console.error('No response received for social login');
          // Handle error
        }
      } else {
        dispatch(apiError(new Error('Vendor does not exist')));
      }
    }
  } catch (error) {
    dispatch(apiError(error));
  }
};

export const resetLoginFlag = () => async (dispatch: any) => {
  try {
    const response = dispatch(reset_login_flag());
    return response;
  } catch (error) {
    dispatch(apiError(error));
  }
};